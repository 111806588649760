import React, { useCallback, useEffect, useState } from "react";
import PreviousNextContainer from "./PreviousNextContainer";
import { useWebServer } from "../../../../../providers";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../AppRouter/routes";
import { fetchTrays } from "../../../../../actions";
import { connect } from "react-redux";
import _ from "lodash";

const Index = ({
  count,
  trayId,
  trayList,
  toolId,
  selectedDevice,
  page,
  pageSize,
  order,
  orderBy,
  fetchTrays,
  children,
  trayValid,
  setSavedHistoryCaptureBothSide,
  setCaptureBothSideModal,
  otherSideDone,
}) => {
  const [shouldDisablePreviousTray, setShouldDisablePreviousTray] =
    useState(false);
  const [shouldDisableNextTray, setShouldDisableNextTray] = useState(false);
  const [previousId, setPreviousId] = useState(-1);
  const [nextId, setNextId] = useState(-1);
  const { sendRequest } = useWebServer();
  const history = useHistory();
  const [fetched, setFetched] = useState(false);

  const fetchTray = useCallback(async () => {
    await fetchTrays(
      toolId,
      {
        page: Math.floor(trayList.length / pageSize),
        pageSize,
        orderBy,
        order,
      },
      sendRequest,
      selectedDevice
    );
  }, [
    fetchTrays,
    order,
    orderBy,
    pageSize,
    selectedDevice,
    sendRequest,
    toolId,
    trayList?.length,
  ]);

  useEffect(() => {
    let curIndex = trayList.indexOf(trayId);
    let tmpPreviousId = trayList[curIndex - 1];
    let tmpNextId = trayList[curIndex + 1];

    setPreviousId(tmpPreviousId);
    setNextId(tmpNextId);
    if (!(tmpPreviousId && curIndex !== -1) || !trayValid) {
      setShouldDisablePreviousTray(true);
    } else {
      setShouldDisablePreviousTray(false);
    }
    if (!(tmpNextId && curIndex !== -1) || !trayValid) {
      setShouldDisableNextTray(true);
    } else {
      setShouldDisableNextTray(false);
    }

    if (
      curIndex <= Math.floor(pageSize / 3) &&
      curIndex !== -1 &&
      trayList.length < count
    ) {
      if (!fetched) {
        fetchTray();
        setFetched(true);
      }
    } else {
      setFetched(false);
    }
  }, [trayId, trayList, count, fetchTray, fetched, pageSize, trayValid]);

  const handlePreviousTray = () => {
    if (!shouldDisablePreviousTray) {
      if (!otherSideDone && history.location.pathname === ROUTES.captureTray) {
        setSavedHistoryCaptureBothSide(
          ROUTES.viewTray.replace(":id", previousId)
        );
        setCaptureBothSideModal(true);
      } else {
        history.push(ROUTES.viewTray.replace(":id", previousId));
      }
    }
  };

  const handleNextTray = () => {
    if (!shouldDisableNextTray) {
      if (!otherSideDone && history.location.pathname === ROUTES.captureTray) {
        setSavedHistoryCaptureBothSide(ROUTES.viewTray.replace(":id", nextId));
        setCaptureBothSideModal(true);
      } else {
        history.push(ROUTES.viewTray.replace(":id", nextId));
      }
    }
  };

  return (
    <PreviousNextContainer
      handlePreviousTray={handlePreviousTray}
      handleNextTray={handleNextTray}
      shouldDisablePreviousTray={shouldDisablePreviousTray}
      shouldDisableNextTray={shouldDisableNextTray}
      children={children}
    />
  );
};

export const mapStateToProps = (state) => {
  const { page, pageSize, order, orderBy } = state?.settings?.Tray;
  const { selectedDevice } = state.devices;
  if (!selectedDevice)
    return { count: 0, selectedDevice, page, pageSize, order, orderBy };
  const { trays: trayList, id } = state.devices[selectedDevice];

  const { count } = _.isPlainObject(trayList) ? trayList : {};
  const trayListArray = _.orderBy(
    Object.values(state.devices[selectedDevice]?.trays || {}),
    ["createdOn"],
    ["desc"]
  ).map((s) => s.id);
  const trayId = state?.capture?.tray?.id;

  return {
    count,
    toolId: id,
    trayId,
    trayList: trayListArray,
    selectedDevice,
    page,
    pageSize,
    order,
    orderBy,
  };
};

export default connect(mapStateToProps, { fetchTrays })(Index);
