import React from "react";
import SetupWifiButton from "./SetupWifiButton";
import { useHistory } from "react-router-dom";
import ROUTES from "./../../../AppRouter/routes";

const Index = (props) => {
  const { handleClose } = props;
  let history = useHistory();

  const onClick = () => {
    handleClose();
    history.push(ROUTES.deviceSetup);
  };

  return (
    <div>
      <SetupWifiButton onClick={onClick} />
    </div>
  );
};

export default Index;
