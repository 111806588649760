import React, { useEffect, useState } from "react";
import { Typography, Box, Grid, TextField, Tooltip, Button } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';
import ResponsiveIconButton from '../../../../Forms/ResponsiveIconButton'
import AutoCompleteField from "../../../../Forms/AutoCompleteField";
import MapIcon from '@mui/icons-material/Map';
import MapCenterInput from "./MapCenterInput";
import ResponsiveButton from "../../../../Forms/ResponsiveButton";

const fontSx = {
    fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"]
}

const buttonStyles = {
    autofillButton: {
        color: 'status.info',
        borderColor: 'status.info',
    },
    infoButton: {
        color: 'status.info',
    },
}

const numberInputSx = {
    '& .MuiOutlinedInput-input': {
        fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
        py: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", "0.5rem", "0.5rem"],
    }
}

const Settings = ({
    email,
    name,
    setAutofillModalOpen,
    setAlgorithmSettingModalOpen,
    organization,
    mapCenter,
    timeZoneOptions,
    timeZone,
    updateUserTimeZoneSettings,
    addressSearchRadius,
    updateUserAddressSearchRadiusSettings,
    updateUserMapCenterSettings,
    mapCenterPlacementMode,
    setMapCenterPlacementMode,
    saveUserSetting }) => {
    const [value, setValue] = useState({
        value: "AUTO",
        abbr: "",
        offset: 0,
        isdst: false,
        text: "Auto Dectect",
        utc: [""],
    });
    const [inputValue, setInputValue] = useState('');

    const handleSave = () => {
        saveUserSetting(timeZone.value, mapCenter, addressSearchRadius)
    }

    useEffect(() => {
        setValue(timeZone)
    }, [timeZone])

    return <Box sx={{
        width: '100%',
        px: '1rem',
        py: '5rem'
    }}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography sx={fontSx} className={" text-gray-600 text-center"}>User Info</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={fontSx} className={" text-gray-600"}>Name: </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={fontSx} >{name}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={fontSx} className={" text-gray-600"}>Organization: </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={fontSx} >{organization}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={fontSx} className={" text-gray-600"}>Email: </Typography>
            </Grid>
            <Grid item xs={8}>
                <Typography sx={fontSx}  >{email}</Typography>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={fontSx} className={" text-gray-600"}>Map Center: </Typography>
            </Grid>
            <Grid item xs={8}>
                <Grid container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center">
                    <Grid item xs={10}>
                        <MapCenterInput mapCenter={mapCenter}
                            updateUserMapCenterSettings={(e) => {
                                updateUserMapCenterSettings(e)
                            }} />
                    </Grid>
                    <Grid item xs={2}>
                        <Tooltip title={
                            <Typography variant="subtitle1">Click to enable map center placement mode on map</Typography>}
                        >
                            <Button onClick={() => setMapCenterPlacementMode(true)} >
                                <MapIcon color={mapCenterPlacementMode ? "disabled" : 'primary'} />
                            </Button>
                        </Tooltip>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={fontSx} className={" text-gray-600"}>Address Search Radius: </Typography>
            </Grid>
            <Grid item xs={8} sx={{ alignItems: 'center', display: 'flex' }}>
                <TextField value={addressSearchRadius} type="number" inputProps={{ step: 10 }} sx={numberInputSx}
                    onChange={(event) => {
                        let value = parseInt(event.target.value, 0)
                        updateUserAddressSearchRadiusSettings(value)
                    }}
                    onBlur={(event) => {
                        if (event.target.value === '' || parseInt(event.target.value) <= 0) {
                            updateUserAddressSearchRadiusSettings(100)
                        }
                    }}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography sx={fontSx} className={" text-gray-600"}>Time Zone: </Typography>
            </Grid>
            <Grid item xs={8}>
                <AutoCompleteField
                    noLabel={true}
                    value={value}
                    onChange={(event, newValue) => {
                        updateUserTimeZoneSettings(newValue?.value)
                        setValue(newValue);
                    }}
                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    getOptionLabel={(option) => {
                        return option.abbr + ' ' + option.text
                    }}
                    isOptionEqualToValue={(option, value) => {
                        return option.value === value?.value
                    }}
                    options={timeZoneOptions}
                />
            </Grid>
            <Grid item xs={4}>
                <Typography sx={fontSx} className={" text-gray-600"}>Specimen Autofill </Typography>
            </Grid>
            <Grid item xs={8} sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }}>
                <ResponsiveIconButton onClick={() => setAutofillModalOpen(true)}>
                    <SettingsIcon
                        sx={buttonStyles.infoButton}
                    />
                </ResponsiveIconButton>
            </Grid>
            <Grid item xs={4}>
                <Typography sx={fontSx} className={" text-gray-600"}>Algorithm Setting </Typography>
            </Grid>
            <Grid item xs={8} sx={{
                justifyContent: 'center',
                alignItems: 'center',
                display: 'flex'
            }}>
                <ResponsiveIconButton onClick={() => setAlgorithmSettingModalOpen(true)}>
                    <SettingsIcon
                        sx={buttonStyles.infoButton}
                    />
                </ResponsiveIconButton>
            </Grid>
        </Grid>
        <Grid item xs={8} sx={{
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex'
        }}>
            <ResponsiveButton
                variant="contained"
                style={{
                    color: "#2196F3",
                    backgroundColor: "white",
                    borderColor: "rgba(63, 81, 181, 0.5)",
                    border: "1px solid",
                    boxShadow: "none",
                }}
                onClick={handleSave}
            >
                SAVE
            </ResponsiveButton>
        </Grid>

    </Box>
};

export default Settings;
