import React from "react";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from '@mui/icons-material/Settings';
import { Typography } from "@mui/material";

const SettingsButton = (props) => {
  const { onClick } = props;
  return (
    <MenuItem color="primary" onClick={onClick}>
      <SettingsIcon />
      <Typography sx={{ pl: 1 }}>Settings</Typography>
    </MenuItem>
  );
};

export default SettingsButton;
