import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "../../../AppRouter/routes";

// import UnderConstruction from "../../../UnderConstruction";
import Settings from "./Settings";
import SpecimenDetails from "./SpecimenDetails";
import SuperUserPanel from "./SuperUserPanel";
import TrapSite from "./TrapSite";
import Device from "./Device";

const DetailsRouter = () => {
  return (
    <Switch>
      <Route exact path={routes.detailsTab}>
        <Redirect to={routes.trapSite} />
      </Route>
      <Route path={routes.trayDetails}>
        <SpecimenDetails />
      </Route>
      <Route path={routes.trapSite}>
        <TrapSite />
      </Route>
      <Route path={routes.superUserPanel}>
        <SuperUserPanel />
      </Route>
      <Route path={routes.settings}>
        <Settings />
      </Route>

      <Route path={routes.toolDetails}>
        <Device />
      </Route>
    </Switch>
  );
};

export default DetailsRouter;
