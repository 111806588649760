import React, { useEffect, useState } from "react";
//import "./styles/app.css";
import { Provider } from "react-redux";
import { useWebServer } from "./providers";
import { createStore, applyMiddleware } from "redux";
import reduxWebsocket from "./middlewares/react-redux-websocket-middleware";
import reduxThunk from "redux-thunk";
import { Auth0Provider, useAuth0 } from "./providers/react-auth0-spa";
import WebServerProvider from "./providers/WebServerProvider";
// import PhotonProvider from "./providers/PhotonProvider";
import history from "./providers/history";
import AppRouter from "./components/AppRouter";
import reducers from "./reducers";

const ReduxProvider = ({ composeEnhancers }) => {
  const { loading } = useAuth0();
  const { getWebsocketUrl, getToken } = useWebServer();
  const [store, setStore] = useState();
  useEffect(() => {
    const makeStore = async () => {
      const s = createStore(
        reducers,
        composeEnhancers(
          applyMiddleware(
            reduxThunk,
            reduxWebsocket([getWebsocketUrl(), getToken])
          )
        )
      );
      setStore(s);
    };
    if (loading === false) makeStore();
  }, [loading, composeEnhancers, getToken, getWebsocketUrl]);

  return store ? (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  ) : null;
};

const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const App = ({ composeEnhancers }) => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      client_id={process.env.REACT_APP_AUTH0_CLIENT_ID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirect_uri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={"localstorage"}
    >
      <WebServerProvider>
        {/* <PhotonProvider> */}
        <ReduxProvider composeEnhancers={composeEnhancers} />
        {/* </PhotonProvider> */}
      </WebServerProvider>
    </Auth0Provider>
  );
};

export default App;
