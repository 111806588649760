import React, { useState } from "react";
import RebootButton from "./RebootButton";
import ConfirmDialog from "./../../../ConfirmDialog";
import { connect } from "react-redux";
import { rebootDevice } from "../../../../actions";

const Index = (props) => {
  const { handleClose, deviceInfo, rebootDevice, deviceUuid } = props;
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  const { stateMachine, displayName } = deviceInfo;

  const onClick = () => {
    setShowLogoutConfirm(true);
  };

  const onConfirm = () => {
    handleClose();
    if (stateMachine && stateMachine !== "OFF") {
      rebootDevice(deviceUuid);
    }
  };

  const computeText = (stateMachine, displayName) => {
    if (!displayName) {
      return "Please select a device.";
    }

    if (stateMachine === "OFF") {
      return `${displayName} is off, Please turn on the machine first.`;
    }
    return `Are you sure you want to reboot ${displayName}?`;
  };

  return (
    <div aria-describedby="Logout popup">
      <ConfirmDialog
        title={"Reboot"}
        children={computeText(stateMachine, displayName)}
        open={showLogoutConfirm}
        setOpen={setShowLogoutConfirm}
        onConfirm={onConfirm}
      />
      <RebootButton onClick={onClick} />
    </div>
  );
};

export const mapStateToProps = (state) => {
  const selectedDevice = state.devices?.selectedDevice || null;
  const deviceInfo = state.devices[selectedDevice] || {};
  return {
    deviceUuid: selectedDevice,
    deviceInfo,
  };
};

export default connect(mapStateToProps, { rebootDevice })(Index);
