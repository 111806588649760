import React from "react";
import SideBar from "./SideBar";
import { useHistory, useLocation } from "react-router-dom";

const Index = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  const getPathRoot = ({ pathname }) => {
    if (pathname !== undefined) {
      const pathRoot = `/${pathname.split("/")[1]}`;
      if (
        pathRoot !== "/data" &&
        pathRoot !== "/details" &&
        pathRoot !== "/trays"
      ) {
        return "/data";
      }
      return pathRoot;
    }
  };

  return (
    <SideBar
      onChange={handleChange}
      pathname={pathname}
      getPathRoot={getPathRoot}
    />
  );
};

export default Index;
