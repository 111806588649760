import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  nextSpecimen,
  prevSpecimen,
  uploadSpecimenMetadata,
  uploadSpecimenBatchMetadata,
  updateSpecimenFromMetadata,
  setMetadataErrors,
  updateSpecimenAutoMetadata,
  updateSpecimenCaptureMetadata,
  fetchPlateWells,
  updatePlateWells,
  updateSpecimenTagOptions,
} from "../../../../../actions";
import {
  DefaultWellAutocompleteOptions,
  DuplicateOptionsEnum,
  OverwriteOptionsEnum,
} from "../../../../../utils/const";
import {
  filterSortedList,
  insertSortedSet,
  convertToRanges,
  convertWellsToRanges,
} from "../../../../../utils/helpers";
import { specimenAutoFillDataSubmit } from "../../../../Forms/SpecimenMetadataSubmit";
import ConfirmDialog from "../../../../ConfirmDialog";
import OptionsDialog from "../../../../OptionsDialog";
import { useWebServer } from "../../../../../providers/WebServerProvider";
import {
  getAutofillOverwriteMessage,
  getSkipOverwriteMessage,
  getAutocompleteOptions,
  getAutofillOverwriteCount,
  getDuplicateWells,
} from "../../../../../utils/autofill";
import Specimen from "./Specimen";
import {
  formatCaptureData,
  processGenusSpecies,
} from "../../../../Forms/SpecimenMetadataSubmit";
import { diff } from 'deep-object-diff'

const Index = ({
  autofillSettings,
  uploadSpecimenMetadata,
  updateSpecimenFromMetadata,
  setMetadataErrors,
  nextSpecimen,
  prevSpecimen,
  selectedDevice,
  fetchPlateWells,
  updatePlateWells,
  traySide,
  frontImage,
  backImage,
  specimenNum,
  lastSpecimenNum,
  algGenus,
  algSpecies,
  algSpeciesConfidence,
  algRegional,
  algSex,
  nrAlgGenus,
  nrAlgSpecies,
  nrAlgSpeciesConfidence,
  nrAlgRegional,
  specimenId,
  defaultInstitutionId,
  trayId,
  capturing,
  disableCapturing,
  displayMetadataErrors,
  plateAutoCompleteOptions,
  updateSpecimenAutoMetadata,
  dispatch,
  uploadSpecimenBatchMetadata,
  updateSpecimenCaptureMetadata,
  wellAutocompleteOptions,
  originalWell,
  originalPlate,
  tagOptions,
  updateSpecimenTagOptions,
  specimenPosList,
  specimens,
  userAlgConfidence,
  vectorType,
  ...otherProps
}) => {
  const [curSpe, setCurSpe] = useState({});
  const [initialized, setInitialized] = useState(false);
  const [specimensArray, setSpecimensArray] = useState(specimens);
  const [metadata, setMetadata] = useState();
  const [loading, setLoading] = useState(true);
  const [updatingDisplayed, setUpdatingDisplayed] = useState(false);
  const [autofillModalOpen, setAutofillModalOpen] = useState(false);
  const [autofillProtectionDialog, setAutofillProtectionDialog] =
    useState(false);
  const [autofillProtectionData, setAutofillProtectionData] = useState([]);
  const [shouldAllowAutofill, setShouldAllowAutofill] = useState({
    shouldAllowAutofill: false,
    metadataFilled: false,
    autofillSettingsFilled: false,
  });
  const { sendRequest } = useWebServer();
  const [wellPopup, setWellPopup] = useState(false);
  const [duplicateAutofillPopup, setDuplicateAutofillPopup] = useState(null);
  const [curTraySideForImage, setCurTraySideForImage] = useState(traySide);

  useEffect(() => {
    if (specimenId) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [specimenId]);

  useEffect(() => {
    setUpdatingDisplayed(false);
  }, [specimenNum]);

  useEffect(() => {
    const cur = specimensArray?.[specimenNum];
    const speciesChecked = autofillSettings?.speciesChecked;
    const sexChecked = autofillSettings?.sexChecked;
    const plateAndWellChecked = autofillSettings?.plateAndWellChecked;
    const lifeStageChecked =
      cur?.lifeStage && autofillSettings?.lifeStageChecked;
    const gonotrophicChecked =
      cur?.gonotrophic && autofillSettings?.gonotrophicChecked;

    const checkAllowAutofill = () => {
      const speciesFilled = cur?.genusSpecies && speciesChecked;
      const sexFilled = cur?.sex && sexChecked;
      const plateWellFilled =
        cur?.plate && plateAndWellChecked && cur?.well && plateAndWellChecked;
      const lifeStageFilled = cur?.lifeStage && lifeStageChecked;
      const gonotrophicFilled = cur?.gonotrophic && gonotrophicChecked;

      return {
        shouldAllowAutofill:
          (speciesFilled ||
            sexFilled ||
            plateWellFilled ||
            lifeStageFilled ||
            gonotrophicFilled) &&
          specimenNum !== lastSpecimenNum,
        metadataFilled:
          cur?.genusSpecies ||
          cur?.sex ||
          (cur?.plate && cur?.well) ||
          cur?.lifeStage ||
          cur?.gonotrophic,
        autofillSettingsFilled:
          speciesChecked ||
          sexChecked ||
          plateAndWellChecked ||
          lifeStageChecked ||
          gonotrophicChecked,
      };
    };

    setShouldAllowAutofill(checkAllowAutofill());
  }, [
    autofillSettings,
    specimenNum,
    lastSpecimenNum,
    specimenId,
    specimensArray,
  ]);

  const handleNextSpecimen = async (form) => {
    if (Object.keys(form.errors).length === 0) {
      nextSpecimen();
    }
  };

  const handlePreviousSpecimen = (form) => {
    if (Object.keys(form.errors).length === 0) {
      prevSpecimen();
    }
  };

  const handleFieldUpdate = (updatedMetaData) => {
    // dispatch(initialize("SpecimenCapture", updatedMetaData));
  };

  const handleAutofill = async (form) => {
    //find out what is the current value that will be autofill and the autofill setting must be true
    if (!(Object.keys(form.errors)?.length === 0)) {
      return;
    }
    let indexArray = [...Array(lastSpecimenNum - specimenNum).keys()].map(
      (i) => i + specimenNum + 1
    );
    const speciesChecked = autofillSettings?.speciesChecked;
    const sexChecked = autofillSettings?.sexChecked;
    const plateAndWellChecked = autofillSettings?.plateAndWellChecked;
    const lifeStageChecked = autofillSettings?.lifeStageChecked;
    const gonotrophicChecked = autofillSettings?.gonotrophicChecked;

    let filledSpecimens = [];

    for (let i of indexArray) {
      const curGenus = specimensArray?.[i]?.genusSpecies;
      const curSex = specimensArray?.[i]?.sex;
      const curPlate = specimensArray?.[i]?.plate;
      const curWell = specimensArray?.[i]?.well;
      const curLifeStage = specimensArray?.[i]?.lifeStage;
      const curGonotrophic = specimensArray?.[i]?.gonotrophic;

      let filledFields = {};
      if (speciesChecked && curGenus && curGenus?.length !== 0) {
        filledFields = { ...filledFields, genusSpecies: curGenus };
      }
      if (sexChecked && curSex && curSex?.length !== 0) {
        filledFields = { ...filledFields, sex: curSex };
      }
      if (
        plateAndWellChecked &&
        ((curPlate && curPlate?.length !== 0) ||
          (curWell && curWell?.length !== 0))
      ) {
        filledFields = { ...filledFields, plate: curPlate, well: curWell };
      }

      if (lifeStageChecked && curLifeStage && curLifeStage?.length !== 0) {
        filledFields = { ...filledFields, lifeStage: curLifeStage };
      }

      if (
        gonotrophicChecked &&
        curGonotrophic &&
        curGonotrophic?.length !== 0
      ) {
        filledFields = { ...filledFields, gonotrophic: curGonotrophic };
      }

      if (Object.keys(filledFields).length > 0) {
        filledSpecimens.push({ [i]: filledFields });
      }
    }

    // //check duplicate well in auto fill
    setAutofillProtectionData(filledSpecimens);
    if (filledSpecimens.length > 0) {
      setAutofillProtectionDialog(true);
    } else {
      let duplicateWells = [];
      if (
        specimensArray?.[specimenNum]?.plate &&
        specimensArray?.[specimenNum]?.well &&
        autofillSettings?.plateAndWellChecked
      ) {
        duplicateWells = getDuplicateWells(
          specimensArray?.[specimenNum]?.well,
          indexArray.length,
          wellAutocompleteOptions
        );
      }
      if (duplicateWells.length > 0) {
        setDuplicateAutofillPopup({
          kwargs: {
            overwriteChoice: OverwriteOptionsEnum.overwrite,
            lastSpecimenNum,
            duplicateWells,
            autocompleteOptions: wellAutocompleteOptions,
          },
          skipOptions: [],
        });
      } else {
        //start auto fill
        submitAutofillData();
      }
    }
  };

  const submitAutofillData = async (params = {}) => {
    const {
      duplicateWellsChoice = DuplicateOptionsEnum.overwrite,
      overwriteChoice = OverwriteOptionsEnum.overwrite,
      lastAutofillIdx = -1,
      autocompleteOptions = null,
    } = params;

    await specimenAutoFillDataSubmit(
      specimensArray,
      specimenNum,
      autofillSettings,
      trayId,
      selectedDevice,
      updateSpecimenAutoMetadata,
      uploadSpecimenBatchMetadata,
      handleFieldUpdate,
      updateSpecimenCaptureMetadata,
      autocompleteOptions ? autocompleteOptions : wellAutocompleteOptions,
      setMetadata,
      duplicateWellsChoice,
      overwriteChoice,
      lastAutofillIdx === -1 ? lastSpecimenNum - specimenNum : lastAutofillIdx,
      specimenPosList,
      setSpecimensArray
    );
  };
  ////////////////////

  const loadingAlgResults =
    !(algGenus || algGenus === "") &&
    !(algSpecies || algSpecies === "") &&
    !(algSpeciesConfidence || algSpeciesConfidence === 0);
  const onBlurGenusSpecies = (value, form) => {
    if (!!value && value !== "0") {
      let newValue = _.chain(value).trim().upperFirst().value();
      if (!newValue.includes(" ")) newValue = `${newValue} spp`;
      form.form.mutators.setValue("genusSpecies", newValue);
    }
  };

  const onBlurWell = (value, form) => {
    const newValue =
      value.length === 2 ? value.charAt(0) + "0" + value.charAt(1) : value;
    form.form.mutators.setValue("well", newValue);
  };

  const onBlurPlate = (value, form) => {
    fetchPlateWells(value, sendRequest);
  };

  const normalizeGenusSpecies = (value) => _.upperFirst(value);

  const normalizeWell = (value) => {
    value = _.chain(value)
      .upperFirst()
      .truncate({ length: 3, omission: "" })
      .value();
    if (value.length === 2) {
      const i1 = parseInt(value.charAt(1));
      if (!(i1 || i1 === 0)) {
        return value.charAt(0);
      }
      if (i1 > 1) {
        return value.charAt(0) + "0" + value.charAt(1);
      }
    } else {
      const num = parseInt(value.slice(1));
      if (!num || num > 12) {
        return value.slice(0, 2);
      }
    }
    return value;
  };

  const calcAndSetDuplicateAutofillPopupOpts = (overwriteChoice, kwargs) => {
    // See if we need to go to duplicate message

    let fillWell = curSpe?.well;
    const lastAutofillIdx =
      overwriteChoice === OverwriteOptionsEnum.autofill
        ? getAutofillOverwriteCount(
          autofillProtectionData,
          specimenId,
          specimenNum
        ) - specimenNum
        : lastSpecimenNum - specimenNum;
    let autocompleteOptions = null;
    if (fillWell && autofillSettings.plateAndWellChecked) {
      autocompleteOptions = getAutocompleteOptions(
        overwriteChoice,
        wellAutocompleteOptions,
        specimensArray,
        specimenNum,
        lastAutofillIdx
      );
      const duplicateWells = getDuplicateWells(
        specimensArray?.[specimenNum]?.well,
        lastSpecimenNum - specimenNum,
        autocompleteOptions
      );
      if (duplicateWells.length > 0) {
        setDuplicateAutofillPopup({
          kwargs: {
            overwriteChoice,
            lastAutofillIdx,
            duplicateWells,
            autocompleteOptions,
            ...kwargs,
          },
          skipOptions: [],
        });
        return;
      }
    }
    // if we're skipping the duplicate options, then we need to go to the next step
    submitAutofillData({
      duplicateWellsChoice: DuplicateOptionsEnum.none,
      overwriteChoice,
      lastAutofillIdx,
      autocompleteOptions,
      ...kwargs,
    });
  };

  useEffect(() => {
    if (!initialized) {
      setSpecimensArray(specimens);
      setInitialized(true);
    }
    if (
      Array.isArray(specimensArray) &&
      Array.isArray(specimens) &&
      specimensArray.length === specimens.length
    ) {
      for (let i = 0; i < specimensArray?.length; i++) {
        let difference = diff(specimensArray[i], specimens[i]);
        if (difference?.["id"]) {
          setSpecimensArray(specimens);
          break;
        }
      }
    }
  }, [specimenNum, specimens, initialized, specimensArray]);

  useEffect(() => {
    setInitialized(false);
  }, [specimenId]);

  useEffect(() => {
    setCurSpe(specimensArray?.[specimenNum]);
  }, [specimensArray, specimenNum]);

  const saveCurrentChange = async (value, difference, isSpecimenChanged) => {
    let newSpecimensValues = JSON.parse(JSON.stringify(specimensArray));
    newSpecimensValues[specimenNum] = JSON.parse(JSON.stringify(value));
    let result = JSON.parse(JSON.stringify(value));
    setSpecimensArray(newSpecimensValues);
    if (result?.genusSpecies || result?.genusSpecies === "") {
      const { genusSpecies, ...rest } = result;
      result = { ...rest, ...processGenusSpecies(genusSpecies) };
    }
    let tmpCaptureData = formatCaptureData(specimenId, result);
    await updateSpecimenCaptureMetadata({
      [specimenNum]: JSON.parse(JSON.stringify(tmpCaptureData)),
    });
    await updateSpecimenFromMetadata(
      trayId,
      selectedDevice,
      specimenNum,
      specimenId,
      result
    );

    if (specimenId === null) {
      return;
    }

    //parse genus species
    if (Object.keys(difference).includes("genusSpecies")) {
      difference = {
        ...difference,
        ...processGenusSpecies(difference?.["genusSpecies"] || ""),
      };
    }
    let batchMetadata = {
      [specimenId]: {
        humanId: _.pick(difference, [
          "genus",
          "species",
          "sex",
          "gonotrophic",
          "lifeStage",
        ]),
        molecularId: _.pick(difference, ["plate", "well"]),
        specimenId: specimenId,
        institutionId: tmpCaptureData?.institutionId,
        tags: result.tags,
      },
    };

    //upload to the server
    if (!isSpecimenChanged) {
      await uploadSpecimenBatchMetadata(trayId, { batchMetadata });
    }
    //console.log(batchMetadata);
  };

  useEffect(() => {
    setCurTraySideForImage(traySide);
  }, [traySide, specimenId])

  const flipTraySide = () => {
    if (curTraySideForImage === "front" && backImage) {
      setCurTraySideForImage("back")
    } else if (curTraySideForImage === "back" && frontImage) {
      setCurTraySideForImage('front')
    }
  }

  return (
    <div>
      <OptionsDialog
        title={`Duplicate Well(s) In Autofill`}
        children={
          <>
            <div>
              {`The following wells are already in use for plate ${metadata?.[`plate-${specimenId}`] || ""
                }:`}
            </div>
            <p>
              {convertWellsToRanges(
                duplicateAutofillPopup?.kwargs?.duplicateWells || []
              ) || ""}
            </p>
          </>
        }
        defaultValue={`overwrite`}
        open={!!duplicateAutofillPopup}
        setClosed={() => setDuplicateAutofillPopup(null)}
        options={{
          skip: {
            message: `Skip duplicate wells`,
            handler: (kwargs) => {
              //1 for skipDuplicateWells
              submitAutofillData({
                duplicateWellsChoice: DuplicateOptionsEnum.skip,
                ...kwargs,
              });
            },
          },
          overwrite: {
            message: `Continue with duplicate wells`,
            handler: (kwargs) => {
              //2 for overwriteDuplicateWells
              submitAutofillData({
                duplicateWellsChoice: DuplicateOptionsEnum.overwrite,
                ...kwargs,
              });
            },
          },
        }}
        {...duplicateAutofillPopup}
      />
      <ConfirmDialog
        title={`Duplicate well`}
        children={`The well you entered is already in use for plate ${metadata?.[`plate-${specimenId}`] || ""
          }. Continue anyway?`}
        open={wellPopup}
        setOpen={setWellPopup}
        cancelMessage={`Revert`}
        onConfirm={() => {
          // submit("SpecimenCapture");
        }}
      // onClose={() => {
      //   if (metadata[`well-${specimenId}`] === originalWell) {
      //     setPlate(originalPlate);
      //   } else {
      //     setWell(originalWell);
      //   }
      // }}
      />
      <OptionsDialog
        title={`Autofill might overwrite data`}
        children={`Are you sure you want to autofill? You have already entered data for specimens Position \n 
        ${convertToRanges(
          autofillProtectionData.map((val) => Number(Object.keys(val)[0]) + 1)
        )}`}
        defaultValue={`overwrite`}
        open={autofillProtectionDialog}
        setClosed={() => setAutofillProtectionDialog(false)}
        onClose={() => {
          setDuplicateAutofillPopup(null);
        }}
        options={{
          overwrite: {
            message: `Overwrite`,
            handler: (kwargs) => {
              // 0 for overwrite
              calcAndSetDuplicateAutofillPopupOpts(
                OverwriteOptionsEnum.overwrite,
                kwargs
              );
            },
          },
          skip: {
            message: getSkipOverwriteMessage(
              autofillProtectionData,
              lastSpecimenNum,
              specimenNum
            ),
            handler: (kwargs) => {
              // 1 for skip
              calcAndSetDuplicateAutofillPopupOpts(
                OverwriteOptionsEnum.skip,
                kwargs
              );
            },
          },
          autofill: {
            message: getAutofillOverwriteMessage(
              autofillProtectionData,
              specimenId,
              specimenNum
            ),
            handler: (kwargs) => {
              // 2 for autofill
              calcAndSetDuplicateAutofillPopupOpts(
                OverwriteOptionsEnum.autofill,
                kwargs
              );
            },
          },
        }}
      />
      <Specimen
        image={curTraySideForImage === "front" ? frontImage : backImage}
        specimenNum={specimenNum}
        handleNextSpecimen={handleNextSpecimen}
        handlePreviousSpecimen={handlePreviousSpecimen}
        algGenus={algGenus}
        algSpecies={algSpecies}
        algSpeciesConfidence={algSpeciesConfidence}
        algRegional={algRegional}
        algSex={algSex}
        specimenID={specimenId}
        defaultInstitutionId={defaultInstitutionId}
        trayId={trayId}
        updatingDisplayed={updatingDisplayed}
        loading={loading}
        hideFormErrors={!displayMetadataErrors}
        metadata={metadata}
        shouldAllowAutofill={shouldAllowAutofill}
        plateAutoCompleteOptions={plateAutoCompleteOptions}
        autofillModalOpen={autofillModalOpen}
        setAutofillModalOpen={setAutofillModalOpen}
        lastSpecimenNum={lastSpecimenNum}
        handleAutofill={handleAutofill}
        loadingAlgResults={loadingAlgResults}
        onBlurGenusSpecies={onBlurGenusSpecies}
        onBlurWell={onBlurWell}
        onBlurPlate={onBlurPlate}
        normalizeGenusSpecies={normalizeGenusSpecies}
        normalizeWell={normalizeWell}
        wellAutocompleteOptions={wellAutocompleteOptions || []}
        autofillSettings={autofillSettings}
        tagOptions={tagOptions}
        {...otherProps}
        specimenValues={curSpe}
        saveCurrentChange={saveCurrentChange}
        nrAlgGenus={nrAlgGenus}
        nrAlgSpecies={nrAlgSpecies}
        nrAlgSpeciesConfidence={nrAlgSpeciesConfidence}
        nrAlgRegional={nrAlgRegional}
        userAlgConfidence={userAlgConfidence}
        vectorType={vectorType}
        flipTraySide={flipTraySide}
        isTwoSideImaged={frontImage && backImage}
      />
    </div>
  );
};

export const mapStateToProps = (state) => {
  const { selectedDevice } = state.devices;
  if (!selectedDevice) return { selectedDevice };
  let {
    [selectedDevice]: { id: toolId, vectorType },
  } = state.devices;
  if (!vectorType) {
    let curTrayDevice = state.capture?.tray?.device;
    vectorType = state.devices?.[curTrayDevice]?.vectorType;
  }
  const { tray, traySide, specimenOnDisplay, capturing, disableCapturing } =
    state.capture;
  const lastSpecimenNum = Object.keys(tray?.specimens || {}).length - 1;
  const autofillSettings = {
    speciesChecked: state.user.autofillSettings?.species,
    sexChecked: state.user.autofillSettings?.sex,
    plateAndWellChecked: state.user.autofillSettings?.molecular,
    lifeStageChecked: state.user.autofillSettings?.lifeStage,
    gonotrophicChecked: state.user.autofillSettings?.gonotrophic,
    ...state.user.autofillSettings,
  };
  if (tray?.specimens?.[specimenOnDisplay]) {
    const {
      id: trayId,
      specimens: {
        [specimenOnDisplay]: {
          id: specimenId,
          position: specimenNum,
          defaultInstitutionId,
        },
      },
    } = tray;
    const frontImage =
      tray.specimens?.[specimenOnDisplay]?.front?.imgPath || "";
    const backImage =
      tray.specimens?.[specimenOnDisplay]?.back?.imgPath || "";

    let algGenus =
      tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId?.genus;
    let algSpecies =
      tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId?.species;
    let algSpeciesConfidence =
      tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId
        ?.speciesConfidence;
    let algRegional =
      tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId
        ?.regional === undefined
        ? true
        : tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId
          ?.regional;
    let nrAlgGenus =
      tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId2?.genus;
    let nrAlgSpecies =
      tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId2?.species;
    let nrAlgSpeciesConfidence =
      tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId2
        ?.speciesConfidence;
    let nrAlgRegional =
      tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId2
        ?.regional === undefined
        ? true
        : tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId2
          ?.regional;
    let algSex =
      tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId?.sex;
    if (!algSex && algSex !== "") {
      algSex = undefined;
    }

    let algEmpty =
      tray?.specimens?.[specimenOnDisplay]?.[traySide]?.algorithmId?.empty;
    if (!algEmpty && algEmpty !== "") {
      algEmpty = undefined;
    }

    if (algEmpty) {
      if (algEmpty === "dirty" || algEmpty === "Dirty") {
        algGenus = "dirty";
        algSpecies = "cell";
      } else if (algEmpty === "empty" || algEmpty === "Empty") {
        algGenus = "empty";
        algSpecies = "cell";
      }
    }

    let initialValues = state?.form?.SpecimenCapture?.values;

    const specimens = Object.values(tray?.specimens).map(
      ({ humanId, molecularId, institutionId, tags, ...cur }) => {
        return {
          genusSpecies:
            humanId?.genus && humanId?.species
              ? `${humanId?.genus} ${humanId?.species}`
              : undefined,
          institutionId: institutionId,
          plate: molecularId?.plate,
          well: molecularId?.well,
          tags: tags,
          sex: humanId?.sex,
          gonotrophic: humanId?.gonotrophic,
          lifeStage: humanId?.lifeStage,
          id: cur?.id,
          empty:
            ["empty", "dirty"].includes(cur?.front?.algorithmId?.empty) ||
            ["empty", "dirty"].includes(cur?.back?.algorithmId?.empty),
        };
      }
    );

    const specimenPosList = Object.values(tray?.specimens)?.reduce(
      (values, { id, humanId, molecularId, institutionId, position }, i) => {
        values[id] = position;
        return values;
      },
      {}
    );

    const { displayMetadataErrors } = state.settings;
    const { plateWells = {} } = state.organization;
    const contributedPlates = Object.keys(
      _.pickBy(plateWells, (plate, wells) => wells.length > 0)
    );
    const currentPlate = specimens?.[specimenNum]?.["plate"];
    let wellAutocompleteOptions = DefaultWellAutocompleteOptions;
    if (currentPlate) {
      wellAutocompleteOptions = filterSortedList(
        DefaultWellAutocompleteOptions,
        plateWells[currentPlate] || []
      );
    }
    // if viewing tray, allow the original well for this specimen
    const originalWell = tray?.specimens?.[specimenOnDisplay]?.molecularId?.well
      ? tray?.specimens?.[specimenOnDisplay]?.molecularId?.well
      : null;
    const originalPlate = tray?.specimens?.[specimenOnDisplay]?.molecularId
      ?.plate
      ? tray?.specimens?.[specimenOnDisplay]?.molecularId?.plate
      : null;
    if (
      originalWell &&
      originalPlate &&
      currentPlate &&
      currentPlate === originalPlate
    ) {
      wellAutocompleteOptions = insertSortedSet(
        wellAutocompleteOptions,
        originalWell
      );
    }

    const tagOptions = state?.organization?.tags || [];
    let userAlgConfidence =
      state?.user?.algConfidence || state?.user?.algConfidence === 0
        ? state.user.algConfidence / 100
        : 0;

    return {
      selectedDevice,
      frontImage,
      backImage,
      traySide,
      capturing,
      disableCapturing,
      specimenNum,
      lastSpecimenNum,
      algGenus,
      algSpecies,
      algSpeciesConfidence,
      algRegional,
      algSex,
      nrAlgGenus,
      nrAlgSpecies,
      nrAlgSpeciesConfidence,
      nrAlgRegional,
      specimenId,
      defaultInstitutionId,
      trayId,
      initialValues,
      displayMetadataErrors,
      plateAutoCompleteOptions: contributedPlates.map((x) => `${x}`),
      wellAutocompleteOptions,
      originalWell,
      originalPlate,
      autofillSettings,
      tagOptions,
      specimenPosList,
      specimens,
      userAlgConfidence,
      vectorType,
    };
  }

  return { selectedDevice, toolId, autofillSettings };
};

export default connect(mapStateToProps, {
  // submit,
  uploadSpecimenMetadata,
  uploadSpecimenBatchMetadata,
  updateSpecimenFromMetadata,
  setMetadataErrors,
  nextSpecimen,
  prevSpecimen,
  updateSpecimenAutoMetadata,
  updateSpecimenCaptureMetadata,
  fetchPlateWells,
  updatePlateWells,
  updateSpecimenTagOptions,
})(Index);
