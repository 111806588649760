import React, { useEffect } from "react";
import {
    renderRadioGroup,
} from "../../../../../Forms/FormFields";
import { Field } from 'react-final-form'
import {
    Box,
    FormControlLabel,
} from "@mui/material";
import Radio from "@mui/material/Radio";

const SexInput = (props) => {
    const {
        hideFormErrors,
        form,
    } = props

    return (<Box
        sx={{
            mx: [
                "1.5rem",
                "1.5rem",
                "1.5rem",
                "1.5rem",
                "2rem",
                "2rem",
            ],
        }}
    >
        <Field
            name={`sex`}
            component={renderRadioGroup}
            // disabled={loading}
            formControlProps={{
                id: `sex-field-control`,
            }}
            inputProps={{
                id: `sex-field`,
                // eslint-disable-next-line no-useless-computed-key
                ["aria-label"]: "sex",
                style: { display: "flex", flexDirection: "row" },
                // row: true,
            }}

            type="radio"
            onClick={(e) => {
                if (e.target?.value === form?.values?.sex) {
                    form.form.mutators.setValue('sex', '')
                }
            }}
            onBlur={(e) => e.preventDefault()}
            hideFormErrors={hideFormErrors}
            sx={{
                "& .MuiFormControlLabel-label": {
                    fontSize: [
                        ".65rem",
                        ".65rem",
                        ".65rem",
                        "0.65rem",
                        "1rem",
                        "1rem",
                    ],
                    width: "60px",
                },
                "& .MuiSvgIcon-root": {
                    fontSize: [
                        "1rem",
                        "1rem",
                        "1.2rem",
                        "1.2rem",
                        "1.2rem",
                        "1.2rem",
                    ],
                },
                "& .MuiRadio-root": {
                    padding: ["5px", "5px", "5px", "5px", "9px", "9px"],
                },
            }}
        >
            <FormControlLabel
                value="female"
                control={<Radio color={"primary"} id={"female-option"} />}
                label="Female"
                sx={{
                    margin: "-0.25rem",
                    display: "flex",
                }}
                checked={form?.values?.sex === 'female' ? true : false}
            />
            <FormControlLabel
                value="male"
                control={<Radio color={"primary"} id={"male-option"} />}
                label="Male"
                sx={{ margin: "-0.25rem", display: "flex" }}
                checked={form?.values?.sex === 'male' ? true : false}
            />
            <FormControlLabel
                value="unsure"
                control={<Radio color={"primary"} id={"male-option"} />}
                label="Unsure"
                sx={{ margin: "-0.25rem", display: "flex" }}
                checked={form?.values?.sex === 'unsure' ? true : false}
            />
        </Field>
    </Box >)

}

export default SexInput;