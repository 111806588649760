import React from "react";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";

const LogoutButton = (props) => {
  const { onClick } = props;
  return (
    <MenuItem color="primary" onClick={onClick}>
      <ExitToAppIcon />
      <Typography sx={{ pl: 1 }}>Logout</Typography>
    </MenuItem>
  );
};

export default LogoutButton;
