import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Paper, Chip } from "@mui/material";

const chipSx = {
  "& .MuiInputLabel-root": {
    fontSize: [".5rem", ".5rem", ".8rem", ".8rem", "1.15rem", "1.15rem"],
  },
  "& .MuiInputLabel-shrink": {
    transform: {
      md: "translate(12px, 3px) scale(0.75)",
      lg: "translate(12px, 7px) scale(0.75)",
    },
  },
  "& .MuiFilledInput-root": {
    pt: ["1rem", "1rem", ".75rem", ".75rem", "1.25rem", "1.25rem"],
  },
  "& .MuiFilledInput-input": {
    m: ["0rem", "0rem", "0rem", "0rem", ".1rem", ".1rem"],
  },
  "& .MuiAutocomplete-input": {
    padding: "3 !important",
    height: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
  },
  "& .MuiTextField-root": {
    marginTop: [".5rem", ".5rem", ".75rem", ".75rem", "1rem", "1rem"],
  },
  "& .MuiChip-root": {
    height: ["1.5rem", "1.5rem", "1.25rem", "1.25rem", "1.85rem", "1.85rem"],
    m: ["0rem", "0rem", ".15rem", ".25rem", ".25rem", ".25rem"],
  },
  "& .MuiChip-label": {
    fontSize: [".5rem", ".5rem", ".75rem", ".75rem", "1.05rem", "1.05rem"],
    px: [".5rem", ".5rem", ".5rem", ".5rem", ".60rem", ".60rem"],
  },
  "& .MuiChip-deleteIcon": {
    fontSize: [
      "1.5rem",
      "1.5rem",
      "1rem !important",
      "1rem !important",
      "1.5rem !important",
      "1.5rem !important",
    ],
  },
};

export const RenderAutocompleteMultiple = (inputProps) => {
  const {
    input,
    // eslint-disable-next-line no-unused-vars
    value: givenValue,
    // eslint-disable-next-line no-unused-vars
    fieldValue,
    label,
    meta,
    disabled,
    hideFormErrors,
    inputProps: extraProps,
    options,
    helperText,
    multilineFlag = false,
    chipColor = null,
    shadowLabel = false,
    outlined = true,
    ...autocompleteprops
  } = inputProps;
  let error =
    meta?.error?.[0] && !hideFormErrors && (meta.touched || meta.submitFailed)
      ? meta.error[0]
      : undefined;

  let errorIndex = meta?.error?.errorIndex;
  if (errorIndex) {
    error = meta?.error?.message;
  }
  const { onChange, name: inputName, ...rest } = input;
  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    setInputValue('')
  }, [inputName])

  return (
    <Autocomplete
      freeSolo
      fullWidth
      selectOnFocus
      multiple
      inputValue={inputValue}
      onChange={(e, newValue) => {
        onChange([...newValue]);
        if (e?.key === 'Enter') {
          if (inputValue.length > 0
            && !inputProps?.value?.includes(inputValue)) {
            setInputValue('')
          }
        }
        if (e?.target?.parentNode?.className?.includes('clearIndicator')) {
          setInputValue('')
        }
      }}
      value={fieldValue || []}
      disabled={disabled}
      options={options ? options : []}
      {...autocompleteprops}
      ///////// DISABLE TAGS /////////
      // sx={{ display: "none" }}
      ////////////////////////////////
      sx={chipSx}
      ////////////////////////////
      PaperComponent={({ children }) => (
        <Paper
          sx={{
            fontSize: [
              ".5rem",
              ".5rem",
              ".8rem",
              ".8rem",
              "1.15rem",
              "1.15rem",
            ],
          }}
        >
          {children}
        </Paper>
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          let errorColor = false;
          if (errorIndex?.length > 0) {
            if (errorIndex.includes(index)) {
              errorColor = true;
            }
          }

          let curChipColor = errorColor ? "#d32f2f" : chipColor;

          return (
            <Chip
              sx={{
                ...chipSx,
                ...(curChipColor
                  ? {
                    color: curChipColor,
                    borderColor: curChipColor,
                    "& .MuiChip-deleteIcon": { color: curChipColor },
                  }
                  : {}),
              }}
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          );
        })
      }
      renderInput={(params) => (
        <TextField
          {...params}
          {...rest}
          name={inputName}
          label={label}
          variant={outlined ? "outlined" : "standard"}
          error={error ? true : false}
          helperText={error ? error : helperText}
          multiline={multilineFlag}
          InputProps={{
            ...(params?.InputProps || {}),
            ...(rest?.InputProps || {}),
            disableUnderline: true,
          }}
          onChange={(e) => {
            setInputValue(e.target.value)
            console.log(e)
          }}
          onKeyUp={(e) => {
            if (e.key === 'Enter') {
              setInputValue('')
            }
          }}
          onBlur={(e) => {
            if (e.target.value.length > 0) {
              if (!fieldValue.includes(e.target.value)) {
                onChange([...fieldValue, e.target.value]);
                setInputValue('')
              }
            }
          }}
          sx={{
            ...(params?.sx || {}),
            ...(rest?.sx || {}),
            "& .MuiFormLabel-root": {
              ...(fieldValue?.length > 0
                ? {
                  top: outlined
                    ? [
                      ".375rem",
                      ".375rem",
                      "-.5rem",
                      "-.5rem",
                      "-1rem",
                      "-1rem",
                    ]
                    : ["6px", "6px", 0, 0, "-8px", "-8px"],
                }
                : {}),

              ...(shadowLabel
                ? {
                  color: "whitesmoke",
                  textShadow: "2px 4px 3px rgba(0,0,0,0.3)",
                }
                : {}),
            },
          }}
        />
      )}
      {...extraProps}
    />
  );
};
