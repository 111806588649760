import * as React from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ResponsiveButton from '../../../Forms/ResponsiveButton';
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const buttonStyles = {
    button: {
        background: 'status.info',
        color: 'white.main',
    },
}

export default function DownloadMenu({ hasNoFilters, setAllDownloadConfirm, onDownloadData, disableDownloadButton }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (type) => {
        setAnchorEl(null);
        if (type === 'exit') {
            return
        }
        if (type === 'data') {
            hasNoFilters ? setAllDownloadConfirm(true) : onDownloadData(type)
        } else {
            onDownloadData(type)
        }
    };

    return (
        <div>
            <ResponsiveButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                sx={buttonStyles.button}
                variant={"contained"}
                color={"primary"}
                endIcon={<CloudDownloadIcon />}
                onClick={handleClick}
                disabled={disableDownloadButton}
            >
                Download
            </ResponsiveButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose('exit')}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => handleClose('data')}>Raw Data</MenuItem>
                <MenuItem onClick={() => handleClose('abundance')}>Abundance and Diversity Report</MenuItem>
            </Menu>
        </div>
    );
}