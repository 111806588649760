import React, { useState } from "react";
import SuperUserContextMenu from "./ContextMenu";
import MoveDeviceDialog from "./MoveEnvDevice";
import ChangeVectorTypeDialog from "./ChangeVectorTypeDialog";
import ChangeRegionDialog from "./ChangeRegionDialog";
import ChangeHardwareDialog from "./ChangeHardwareIdDialog";

const Index = React.forwardRef((props, ref) => {
  const {
    devices,
    possibleVectorTypes,
    updateDeviceEnv,
    organizations,
    updateDeviceVectorType,
    updateDeviceHardwareId,
  } = props;
  const [device, setDevice] = useState(null);
  const [organizationVectorType, setOrganizationVectorType] = useState(null);
  const [contextMenuEvent, setContextMenu] = useState(null);
  const [deviceEnvDialog, setDeviceEnvDialog] = useState(null);
  const [deviceVectorTypeDialog, setDeviceVectorTypeDialog] = useState(null);
  const [deviceRegionDialog, setDeviceRegionDialog] = useState(null);
  const [hardwareIdDialog, setHardwareIdDialog] = useState(null);
  const [hardwareId, setHardwareId] = useState(null);

  React.useImperativeHandle(ref, () => ({
    handleContextMenu(event, selectedDevice, selectedOrg, row) {
      event.preventDefault();
      setHardwareId(row?.hardwareId);
      setDevice(devices?.[selectedDevice]);
      setOrganizationVectorType(possibleVectorTypes);
      setContextMenu(
        contextMenuEvent === null
          ? {
              mouseX: event.clientX + 2,
              mouseY: event.clientY - 6,
            }
          : // repeated contextmenu when it is already open closes it with Chrome 84 on Ubuntu
            // Other native context menus might behave different.
            // With this behavior we prevent contextmenu from the backdrop to re-locale existing context menus.
            null
      );
    },
  }));

  const handleClose = () => {
    setContextMenu(null);
    setDevice(null);
    setOrganizationVectorType(null);
  };
  /////// Menu Actions ///////
  // MoveDeviceEnv
  const handleMoveDeviceEnv = (newEnv) => {
    console.log(`handleMoveDeviceEnv ${newEnv}`);
    updateDeviceEnv(device.uuid, newEnv);
    handleClose();
  };

  const handleChangeVectorType = (newVectorType) => {
    console.log(`handleChangeDeviceVectorType ${newVectorType}`);
    updateDeviceVectorType(device.uuid, newVectorType);
    handleClose();
  };

  const handleChangeHardwareId = (newHardwareId) => {
    console.log(`handleChangeHardwareId ${newHardwareId}`);
    updateDeviceHardwareId(device.uuid, newHardwareId);
    handleClose();
  };

  const onChangeHardwareId = () => {
    setHardwareIdDialog(true);
  };

  const onMoveDeviceEnv = () => {
    setDeviceEnvDialog(true);
  };

  const onChangeDeviceVectorType = () => {
    setDeviceVectorTypeDialog(true);
  };

  const onChangeDeviceRegion = () => {
    setDeviceRegionDialog(true);
  };
  ////////////////////////////
  return (
    <>
      <MoveDeviceDialog
        actionName={"Move"}
        device={device}
        active={deviceEnvDialog}
        setActive={setDeviceEnvDialog}
        onConfirm={handleMoveDeviceEnv}
      />
      <ChangeVectorTypeDialog
        actionName={"Change"}
        device={device}
        vectorTypesMapping={organizationVectorType}
        active={deviceVectorTypeDialog}
        setActive={setDeviceVectorTypeDialog}
        onConfirm={handleChangeVectorType}
      />
      <ChangeRegionDialog
        actionName={"Change"}
        device={device}
        vectorTypesMapping={organizationVectorType}
        active={deviceRegionDialog}
        setActive={setDeviceRegionDialog}
        onConfirm={handleChangeVectorType}
      />
      <ChangeHardwareDialog
        actionName={"Change"}
        device={device}
        hardwareId={hardwareId}
        active={hardwareIdDialog}
        setActive={setHardwareIdDialog}
        onConfirm={handleChangeHardwareId}
      />
      <SuperUserContextMenu
        device={device}
        handleClose={handleClose}
        contextMenuEvent={contextMenuEvent}
        onMoveDeviceEnv={onMoveDeviceEnv}
        onChangeDeviceVectorType={onChangeDeviceVectorType}
        onChangeDeviceRegion={onChangeDeviceRegion}
        onChangeHardwareId={onChangeHardwareId}
      />
    </>
  );
});

export default Index;
