import React from "react";
import FemaleSexIcon from "../../../../assets/icons/female_sex_icon.svg";
import MaleSexIcon from "../../../../assets/icons/male_sex_icon.svg";
import UnsureSexIcon from "../../../../assets/icons/unsure_sex_icon.svg";
import SpeciesIcon from "../../../../assets/icons/darkerSP.svg";
import DNAIcon from "../../../../assets/icons/twemoji_dna.svg";
import { getSppDisplayName } from "../../../../utils/caseConvert";
import OpacityIcon from '@mui/icons-material/Opacity';
import {
    Typography,
    Box,
} from "@mui/material";
import LoopIcon from '@mui/icons-material/Loop';


const IMAGE_SX = {
    width: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
    height: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem",],

}

const SpecimenBBox = ({ s, i, w, h, topX, topY, border, handleJump }) => {
    return (
        <div
            key={`bbox${i}`}
            style={{
                position: "absolute",
                border: border,
                height: h,
                width: w,
                top: topY,
                left: topX,
                overflow: "hidden",
                padding: 5,
                minWidth: 0,
            }}
            className={
                "flex flex-col justify-between hover:bg-blue-400 hover:bg-opacity-25"
            }
            onClick={handleJump}
            data-testid="bounding-box"
        >
            <Box
                className={"flex flex-col justify-between"}
                sx={{
                    "& img": IMAGE_SX,
                    "& svg": IMAGE_SX,
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        left: 0,
                        visibility: s?.humanId?.gonotrophic
                            ? "visible"
                            : "hidden",
                        lineHeight: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
                    }}
                >
                    <OpacityIcon sx={{ color: 'rgb(255,0,0,0.7)' }} />
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        left: 0,
                        top: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
                        mt: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", "0.5rem", "0.5rem"],
                        visibility: s?.humanId?.lifeStage
                            ? "visible"
                            : "hidden",
                    }}
                >
                    <LoopIcon sx={{ color: 'rgb(26, 93, 26)' }} />
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        right: 0,
                        visibility: s?.humanId?.genus
                            ? "visible"
                            : "hidden",
                    }}
                >
                    <img
                        className={"w-6"}
                        style={{
                            color: "#1520A6",
                        }}
                        src={SpeciesIcon}
                        alt=""
                        title="Species Icon"
                    ></img>
                </Box>
                <Box
                    className={"contegenusnt-center"}
                    sx={{
                        position: "absolute",
                        right: 0,
                        top: ["1.15rem", "1.15rem", "1.15rem", "1.15rem", "1.5rem", "1.5rem"],
                        mt: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", "0.5rem", "0.5rem"],
                        visibility: s?.humanId?.sex ? "visible" : "hidden",
                    }}
                >
                    <img
                        className={"w-4 mx-auto"}
                        src={
                            s?.humanId?.sex === "male"
                                ? MaleSexIcon
                                : s?.humanId?.sex === "female"
                                    ? FemaleSexIcon
                                    : UnsureSexIcon
                        }
                        alt=""
                        title={
                            s?.humanId?.sex === "male"
                                ? "Male Sex Icon"
                                : s?.humanId?.sex === "female"
                                    ? "Female Sex Icon"
                                    : "Unsure Sex Icon"
                        }
                    ></img>
                </Box>
                <Box
                    sx={{
                        position: "absolute",
                        right: 0,
                        top: ["2.4rem", "2.4rem", "2.4rem", "2.4rem", "3.5rem", "3.5rem"],
                        mt: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", "0.5rem", "0.5rem"],
                        visibility: s?.molecularId?.well
                            ? "visible"
                            : "hidden",
                    }}
                >
                    <img
                        className={"w-6"}
                        src={DNAIcon}
                        alt=""
                        title="DNA Icon"
                    ></img>
                </Box>
            </Box>

            <span
                className={
                    "truncate font-semibold text-blue-700 text-center italic hover:opacity-100 z-10"
                }
            >
                <Typography
                    sx={{
                        fontSize: [
                            "0.66rem",
                            "0.66rem",
                            "0.66rem",
                            "0.66rem",
                            "1rem",
                            "1rem",
                        ],
                    }}
                >{
                        (s?.humanId?.genus && s.humanId?.species) ?
                            getSppDisplayName(s?.humanId?.genus, s.humanId?.species, 2)
                            : (s?.bboxGenus || s?.bboxGenus === "") &&
                                (s?.bboxSpecies || s?.bboxSpecies === "")
                                ? getSppDisplayName(s.bboxGenus, s.bboxSpecies, 2)
                                : null}
                </Typography>
            </span>
        </div>
    );
};

export default SpecimenBBox;