import { combineReducers } from "redux";
import { reducer as formReducer } from "redux-form";
import captureReducer from "./captureReducer";
import deviceReducer from "./deviceReducer";
import trapSiteReducer from "./trapSiteReducer";
import websocketReducer from "./websocketReducer";
import notificationReducer from "./notificationReducer";
import licenseReducer from "./licenseReducer";
import settingsReducer from "./settingsReducer";
import organizationReducer from "./organizationReducer";
import wifiReducer from "./wifiReducer";
import userReducer from "./userReducer";
import mapReducer from "./mapReducer";
import dataReducer from "./dataReducer";
import superUserReducer from "./superUserReducer";
import algVersionsReducer from "./algVersionsReducer";

export default combineReducers({
  form: formReducer,
  trapSites: trapSiteReducer,
  devices: deviceReducer,
  capture: captureReducer,
  websocket: websocketReducer,
  notifications: notificationReducer,
  license: licenseReducer,
  settings: settingsReducer,
  organization: organizationReducer,
  wifi: wifiReducer,
  user: userReducer,
  map: mapReducer,
  data: dataReducer,
  superuser: superUserReducer,
  algVersion: algVersionsReducer,
});
