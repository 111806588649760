import React, {
  createRef,
  useEffect,
  useState,
  useRef,
  useCallback,
} from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import FormHelperText from "@mui/material/FormHelperText";
import FormLabel from "@mui/material/FormLabel";
import { Paper, Typography, Tooltip, Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import EditIcon from "@mui/icons-material/Edit";
import _ from "lodash";
import parse from "date-fns/parse";
import { createFilterOptions } from '@mui/material/Autocomplete';


const DEFAULT_DATE_FORMAT = "mm/dd/yy";

const inputSx = {
  "& .MuiInputLabel-root": {
    fontSize: [".5rem", ".5rem", ".8rem", ".8rem", "1.15rem", "1.15rem"],
    transform: {
      md: "translate(12px, 10px) scale(1)",
      lg: "translate(12px, 16px) scale(1)",
    },
  },
  "& .MuiInputLabel-shrink": {
    transform: {
      md: "translate(12px, 3px) scale(0.75)",
      lg: "translate(12px, 7px) scale(0.75)",
    },
  },
  "& .MuiFilledInput-root": {
    pt: [".75rem", ".75rem", ".75rem", ".75rem", "19px", "19px"],
    fontSize: [".5rem", ".5rem", ".75rem", ".75rem", "1rem", "1rem"],
  },
  "& .MuiFilledInput-input": {
    // m: ['0rem', '0rem', '0rem', '0rem', '.2rem', '.2rem'],
    paddingBottom: [
      "0.335rem",
      "0.335rem ",
      "0.335rem !important",
      "0.335rem !important",
      "0.5rem",
      "0.5rem",
    ],
  },
  "& .MuiAutocomplete-input": {
    px: [
      "3px !important",
      "3px !important",
      "3px !important",
      "3px !important",
      "5px !important",
      "5px !important",
    ],
    // py: "5px !important",
    py: [
      "5px !important",
      "5px !important",
      "5px !important",
      "5px !important",
      "7px !important",
      "7px !important",
    ],
    lineHeight: ["1rem", "1rem", "1rem", "1rem", "1.4375rem", "1.4375rem"],
    height: ["1rem", "1rem", "1rem", "1rem", "1.4375rem", "1.4375rem"],
  },
  "& .MuiSvgIcon-root": {
    fontSize: [
      "1rem !important",
      "1rem !important",
      "1rem !important",
      "1rem !important",
      "1.5rem !important",
      "1.5rem !important",
    ],
  },
  "& .MuiAutocomplete-endAdornment": {
    right: "9px",
  },
  "& .MuiFormHelperText-root": {
    fontSize: ["0.5rem", "0.5rem", "0.5rem", "0.5rem", "0.75rem", "0.75rem"],
  },
};


const textAreaSx = {
  "& .MuiInputLabel-root": {
    fontSize: [".5rem", ".5rem", ".8rem", ".8rem", "1.15rem", "1.15rem"],
    transform: {
      md: "translate(12px, 10px) scale(1)",
      lg: "translate(12px, 16px) scale(1)",
    },
  },
  "& .MuiInputLabel-shrink": {
    transform: {
      md: "translate(12px, 3px) scale(0.75)",
      lg: "translate(12px, 7px) scale(0.75)",
    },
    backgroundColor: 'transparent'
  },
  "& .MuiInputBase-input": {
    paddingTop: "0rem",
    paddingBottom: "0rem",
  },
  "& .MuiFilledInput-root": {
    paddingTop: ["1.0rem", "1.0rem", "1.0rem", "1.0rem", "25px", "25px"],
    paddingBottom: ["5px", "5px", "5px", "5px", "8px", "8px"],
    fontSize: [".5rem", ".5rem", ".75rem", ".75rem", "1rem", "1rem"],
    lineHeight: ["1rem", "1rem", "1rem", "1rem", "1.4375rem", "1.4375rem"],
  },
  "& .MuiFormHelperText-root": {
    fontSize: ["0.5rem", "0.5rem", "0.5rem", "0.5rem", "0.75rem", "0.75rem"],
  },
};

const specimenSx = {
  fontSize: [".75rem", ".75rem", ".75rem", "0.75rem", "1.125rem", "1.125rem"],
};

const institutionIdSx = {
  maxWidth: ["8rem", "8rem", "10rem", "10rem", "12rem", "12rem"],
};

const autoCompleteTextAreaSx = {
  ...textAreaSx,
  "& .MuiFilledInput-input": {
    pt: "0 !important",
    pb: "0 !important",
  },
};

export const renderInput = (inputProps) => {
  const {
    input,
    style,
    label,
    meta,
    disabled,
    hideFormErrors,
    inputProps: extraProps,
    // eslint-disable-next-line no-unused-vars
    helperText,
    testId,
  } = inputProps;
  const error = meta?.error
    ? meta?.error?.[0] && !hideFormErrors && (meta.touched || meta.submitFailed)
      ? meta.error[0]
      : meta?.error
        ? meta?.error
        : undefined
    : undefined;
  return (
    <TextField
      {...input}
      style={{ ...style }}
      label={label}
      disabled={disabled}
      variant="filled"
      autoComplete="off"
      error={error ? true : false}
      helperText={error}
      {...extraProps}
      inputProps={{ "data-testid": testId }}
      sx={textAreaSx}
    />
  );
};

export const renderAutocomplete = (inputProps) => {
  const {
    input,
    // eslint-disable-next-line no-unused-vars
    value: givenValue,
    // eslint-disable-next-line no-unused-vars
    fieldValue,
    label,
    meta,
    disabled,
    hideFormErrors,
    inputProps: extraProps,
    options,
    helperText,
    multilineFlag = false,
    ...autocompleteprops
  } = inputProps;
  let error =
    meta?.error?.[0] && !hideFormErrors && (meta.touched || meta.submitFailed)
      ? meta.error[0]
      : undefined;

  if (typeof meta?.error === "string") {
    error = meta?.error;
  }
  if (Array.isArray(meta?.error)) {
    error = meta.error[0];
  }
  const { onChange, ...rest } = input;
  return (
    <Autocomplete
      freeSolo
      fullWidth
      selectOnFocus
      onChange={(e, newValue) => {
        onChange(newValue);
      }}
      value={input?.value || ""}
      disabled={disabled}
      options={options ? options : []}
      {...autocompleteprops}
      sx={multilineFlag ? autoCompleteTextAreaSx : inputSx}
      PaperComponent={({ children }) => (
        <Paper
          sx={{
            fontSize: [
              ".5rem",
              ".5rem",
              ".8rem",
              ".8rem",
              "1.15rem",
              "1.15rem",
            ],
          }}
        >
          {children}
        </Paper>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          {...rest}
          label={label}
          variant="filled"
          error={error ? true : false}
          helperText={error ? error : helperText}
          onChange={(e, newValue) => {
            onChange(e, newValue);
          }}
          multiline={multilineFlag}
        />
      )}
      {...extraProps}
    />
  );
};

export const RenderSelect = (inputProps) => {
  //eslint-disable-next-line
  const {
    input,
    label,
    meta,
    disabled,
    hideFormErrors,
    formControlProps,
    options,
    getOptionLabel,
    filterOptions
  } = inputProps;
  const error =
    meta?.error?.[0] && !hideFormErrors && (meta.touched || meta.submitFailed)
      ? meta.error[0]
      : undefined;
  return (
    <FormControl
      {...formControlProps}
      disabled={disabled}
      error={error}
      style={{ width: "100%" }}
    >
      <Autocomplete
        {...input}
        sx={inputSx}
        options={options}
        autoHighlight
        // {...extraProps}
        getOptionLabel={getOptionLabel}
        renderOption={(props, option) => (
          <li {...props} key={option?.id} value={option?.id}>
            {option.displayName}
          </li>
        )}
        value={input.value || null}
        onChange={(e, newValue) => {
          input.onChange(newValue?.id || "")
        }}
        isOptionEqualToValue={(option, value) => option?.id === value}
        filterOptions={filterOptions || createFilterOptions({
          matchFrom: 'any',
          stringify: (option) => option
        })}
        renderInput={(params) => {
          return <TextField
            {...params}
            variant="filled"
            label={label}
          />

        }}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export const renderRadioGroup = ({
  input,
  meta,
  label,
  disabled,
  formControlProps,
  hideFormErrors,
  inputProps: extraProps,
  ...rest
}) => {
  const error =
    meta?.error?.[0] && !hideFormErrors && (meta.touched || meta.submitFailed)
      ? meta.error[0]
      : undefined;
  return (
    <FormControl
      component="fieldset"
      {...formControlProps}
      variant="filled"
      disabled={disabled}
      error={error}
    >
      <FormLabel disabled={disabled} color="primary">
        {label}
      </FormLabel>
      <RadioGroup {...input} {...extraProps} {...rest} value={input.value} />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export const RenderDatePicker = (props) => {
  const {
    input,
    label,
    disabled,
    setHasErrorsFlag,
    cusMinDate = "2018-01-01",
    handleChange,
  } = props;
  const updateParent = _.debounce(setHasErrorsFlag, 200, { trailing: true });
  const [date, setDate] = useState();
  const textFieldRef = useRef();

  const autoFillDate = (date) => {
    const isValidDate = (d) => {
      return d instanceof Date && !isNaN(d);
    };
    let newStrArr = [];
    let newStr = date;
    let rawInput = textFieldRef?.current?.value;
    if (rawInput?.length === 10) {
      input.onChange(date);
    } else if (rawInput?.length === 8) {
      newStrArr = rawInput.split("/");
      let year = parseInt(newStrArr?.[2]);
      if (year >= 10 && year < 100) {
        newStrArr[2] = "20" + year.toString();
        newStr = newStrArr.join("/");
        if (new Date(newStr) > new Date()) {
          newStrArr[2] = "19" + year.toString();
        }
        newStr = newStrArr.join("/");
      }
      if (year < 10 && year >= 0) {
        newStrArr[2] = "200" + year.toString();
        newStr = newStrArr.join("/");
      }
      if (!newStr) return null;
      let newDate = parse(
        new Date(newStr)?.toISOString().split("T")[0],
        "yyyy-MM-dd",
        new Date()
      );
      if (
        !isValidDate(date) ||
        date?.toISOString().split("T")[0] !==
        newDate.toISOString().split("T")[0]
      ) {
        setDate(newDate);
        handleChange(newDate);
        input.onChange(newDate);
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceAutofill = useCallback(
    _.debounce(autoFillDate, 1000, { trailing: true }),
    []
  );

  useEffect(() => {
    debounceAutofill(date);
    if (textFieldRef?.current?.value.length === 10) {
      debounceAutofill.flush();
    }
  }, [date, debounceAutofill]);

  useEffect(() => {
    // update to new date when input.value change
    if (input.value === "" || input.value === null) {
      setDate(null);
    } else {
      if (Object.prototype.toString.call(input.value) === "[object Date]") {
        if (date !== null) {
          setDate(input.value);
        }
        if (isNaN(input.value)) {
          // d.getTime() or d.valueOf() will also work
          // date object is not valid
        } else {
          if (Object.prototype.toString.call(date) === "[object Date]") {
            if (input.value?.toISOString() !== date?.toISOString())
              setDate(input.value);
          }
          if (date === null) {
            setDate(input.value);
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [input.value]);

  const computeError = (value, error) => {
    const curDate = new Date(value);
    const minDate = new Date(cusMinDate);
    const today = new Date();
    if (error) {
      if (date?.toString() === "Invalid Date") {
        updateParent(true);
        if (
          textFieldRef?.current?.value?.length === 8 ||
          textFieldRef?.current?.value?.length === 10
        ) {
          return "Invalid Date Format";
        }
        return DEFAULT_DATE_FORMAT;
      }
      if (minDate > curDate) {
        updateParent(true);
        return "should be after " + cusMinDate;
      } else if (curDate > today) {
        return "should be on or before today";
      } else {
        updateParent(true);
        return DEFAULT_DATE_FORMAT;
      }
    }
    updateParent(false);
    return "";
  };
  return (
    <DatePicker
      label={label}
      minDate={new Date(cusMinDate)}
      maxDate={new Date()}
      {...input}
      value={date || null}
      onChange={(date) => {
        setDate(date);
        input.onChange(date);
        // handleChange(date);
      }}
      inputRef={textFieldRef}
      clearText
      sx={{
        width: "100%",
        "& .MuiInput-root": {
          mt: ["0.5rem", ".5rem", ".5rem", ".5rem", "1rem", "1rem"],
        },
      }}
      renderInput={(inProps) => {
        return (
          <TextField
            variant="standard"
            sx={{
              margin: 0,
              "& .MuiInput-input": {
                fontSize: [
                  "1rem",
                  ".66rem",
                  ".66rem",
                  ".66rem",
                  "1rem",
                  "1rem",
                ],
              },
              "& .MuiInputLabel-shrink": {
                transform: {
                  md: "translate(0px, 7px) scale(0.75)",
                  lg: "translate(0px, 7px) scale(0.75)",
                },
              },
              "& .MuiInputLabel-root": {
                fontSize: [
                  ".5rem",
                  ".5rem",
                  ".8rem",
                  ".8rem",
                  "1.15rem",
                  "1.15rem",
                ],
              },
              "& .MuiInputAdornment-root": {
                mr: ["1rem", ".5rem", ".5rem", ".5rem", "1rem", "1rem"],
              },
              "& .MuiSvgIcon-root": {
                fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
              },
            }}
            {...inProps}
            disabled={disabled}
            helperText={computeError(date, inProps.error)}
          />
        );
      }}
      testId={"test"}
    />
  );
};

export const RenderTypography = (inputProps) => {
  const {
    input,
    style,
    label,
    inputProps: extraProps,
    defaultInstitutionId,
  } = inputProps;
  const institutionIdRef = createRef();
  const { onChange } = input;
  const { error = undefined } = inputProps?.meta;
  const [openTooltip, setOpenTooltip] = useState(false);
  return (
    <Box>
      <Typography
        sx={{
          ...specimenSx,
          transform: [
            "translate(0px,-6x)",
            "translate(0px,-6px)",
            "translate(0px,-10px)",
            "translate(0px,-10px)",
            "translate(0px,-15px)",
            "translate(0px,-15px)",
          ],
          fontSize: [".5rem", ".5rem", ".6rem", "0.6rem", "0.9rem", "0.9rem"],
        }}
        style={{
          position: "absolute",
          opacity: "0.75",
          display:
            defaultInstitutionId === input?.value || input?.value === ""
              ? "none"
              : "block",
        }}
        ref={institutionIdRef}
      >
        {defaultInstitutionId}
      </Typography>
      <Tooltip
        onPointerEnter={() => setOpenTooltip(true)}
        onPointerLeave={() => setOpenTooltip(false)}
        open={error || openTooltip}
        title={
          <Typography variant="subtitle1">
            {error ? error : "Add/edit instituiton ID\n"}
          </Typography>
        }
        placement="top"
        PopperProps={
          error
            ? {
              sx: {
                "& .MuiTypography-root": {
                  color: "#E10050",
                },
                "& .MuiTooltip-tooltip": {
                  backgroundColor: "rgba(97, 97, 97, 0.25)",
                },
              },
            }
            : {}
        }
      >
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography
            {...input}
            onChange={(e, newValue) => {
              onChange(e.target.value);
            }}
            style={{
              ...style,
              display: "inline",
              outline: `0 solid transparent`,
            }}
            label={label}
            {...extraProps}
            contentEditable={true}
            sx={{ ...specimenSx, ...institutionIdSx }}
            suppressContentEditableWarning={true}
            ref={institutionIdRef}
            component="input"
            value={input?.value}
            onBlur={(e) => {
              if (e.target.value === "") {
                onChange(defaultInstitutionId);
              }
            }}
            size={
              input?.value === ""
                ? 12
                : input?.value
                  ? input?.value.length + 2
                  : 5
            }
          ></Typography>
          <EditIcon
            onClick={() => {
              const institutionId = institutionIdRef.current;
              const text = institutionId.value;
              institutionId.focus();
              institutionIdRef.current.setSelectionRange(
                text.length,
                text.length
              );
            }}
            style={{ display: "inline" }}
            sx={{
              fontSize: [
                "0.85rem",
                "0.85rem",
                "0.85rem",
                "1rem",
                "1.5rem",
                "1.5rem",
              ],
            }}
          />
        </Box>
      </Tooltip >
    </Box >
  );
};
