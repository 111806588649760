import RoomIcon from "@mui/icons-material/Room";
import Fab from "@mui/material/Fab";
import React from "react";
import { Tooltip } from "@mui/material";

const TrapSiteButton = ({ onClick }) => {
  // const { capturing } = useCaptureProvider()

  return (
    <Tooltip title={"Trap Sites"}>
      <Fab
        color="primary"
        aria-label="add-pin"
        onClick={onClick}
        // disabled={capturing}
        sx={{
          margin: ["0rem", "1rem", "1rem", "1.5rem", "1.5rem", "1.5rem"],
          display: ["none", "flex", "flex", "flex", "flex", "flex"],

        }}
      >
        <RoomIcon />
      </Fab>
    </Tooltip >
  );
};

export default TrapSiteButton;
