import {
  ESTABLISH_STATES_DEVICE,
  SELECT_DEVICE,
  UPDATE_DEVICE,
  CREATE_TRAY,
  EDIT_TRAY,
  FETCH_TRAY,
  FETCH_TRAYS,
  DELETE_TRAY,
  UPDATE_SPECIMEN_FROM_METADATA,
  UPDATE_TRAY_FROM_METADATA,
  UPDATE_SPECIMEN_AUTO_METADATA,
  RELOAD_SOCKET,
  CLEAR_TRAY,
  FETCH_DEVICE_MAC_ADDRESS,
  FETCH_DEVICE_MAC_ADDRESS_RESPONSE,
  FINISH_FETCH_MAC_ADDRESS,
  MOVE_DEVICE_ORG,
  RENAME_DEVICE,
  UPDATE_DEVICE_ENV,
  UPDATE_DEVICE_VECTOR_TYPE,
  CHANGE_DEVICE_HARDWARE_ID
} from "../actions/types";
import { camelify } from "./../utils/caseConvert";
import _ from "lodash";
import trayReducer from "./trayReducer";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (
  state = { fetchingMacAddressStatus: "disconnected" },
  action
) => {
  let device_uuid;
  let old;
  switch (action.type) {
    case ESTABLISH_STATES_DEVICE:
      const devices = {
        ...Object.fromEntries(
          Object.entries(action.payload?.initial_states).map(([k, v]) => [
            k,
            { ...camelify(v), uuid: k },
          ])
        ),
        ALLTRAY: {
          id: "ALLTRAY",
          displayName: "all devices",
          uuid: "ALLTRAY",
          env: "ALLTRAY",
        },
      };
      return _.defaultsDeep({}, devices, state);
    case UPDATE_DEVICE_ENV:
      if (action.payload?.deviceUuid && action.payload?.env) {
        return _.defaultsDeep(
          {},
          { [action.payload.deviceUuid]: { env: action.payload.env } },
          state
        );
      }
      return state;
    case UPDATE_DEVICE_VECTOR_TYPE:
      if (action.payload?.deviceUuid && action.payload?.vectorType) {
        return _.defaultsDeep(
          {},
          { [action.payload.deviceUuid]: { vectorType: action.payload.vectorType } },
          state
        );
      }
      return state;
    case SELECT_DEVICE:
      if (action.payload === "none") return _.omit(state, "selectedDevice");
      return { ...state, selectedDevice: action.payload };
    case UPDATE_DEVICE:
      const { device_uuid: uuid, ...otherProps } = action.payload;

      if (uuid)
        return _.defaultsDeep({}, { [uuid]: camelify(otherProps) }, state);
      return state;
    case UPDATE_SPECIMEN_FROM_METADATA:
    case UPDATE_SPECIMEN_AUTO_METADATA:
    case UPDATE_TRAY_FROM_METADATA:
    case FETCH_TRAYS:
    case CREATE_TRAY:
    case FETCH_TRAY:
    case EDIT_TRAY:
    case DELETE_TRAY:
      device_uuid = state?.selectedDevice;
      if (action.type === FETCH_TRAYS) {
        device_uuid = action.payload?.deviceUuid;
        action.payload = _.omit(action.payload, ["deviceUuid"]);
      }
      if (!device_uuid) return state;
      old = { ...state?.[device_uuid] };
      return {
        ...state,
        [device_uuid]: { ...old, trays: trayReducer(old?.trays, action) },
      };
    case MOVE_DEVICE_ORG:
      if (action.payload.device in state) {
        return Object.fromEntries(
          Object.entries(state).filter(
            ([deviceUuid, v]) => deviceUuid !== action.payload.device
          )
        );
      }
      return state;
    case RENAME_DEVICE:
      if (action.payload.device in state) {
        return {
          ...state,
          [action.payload.device]: {
            ...state?.[action.payload.device],
            displayName: action.payload.name,
          },
        };
      }
      return state;
    // case EDIT_SPECIMEN:
    //     device_uuid = state?.selectedDevice;
    //     if (!device_uuid) return state;
    //     old = { ...state?.[device_uuid] }
    //     return _.defaultsDeep({}, { [device_uuid]: { trays: { [action.payload.tray_id]: { specimens: specimenReducer(old.trays.specimens, action) } } } }, state)
    case CHANGE_DEVICE_HARDWARE_ID:
      if (action.payload.device in state) {
        return {
          ...state,
          [action.payload.device]: {
            ...state?.[action.payload.device],
            hardware_id: action.payload.hardwareId,
          },
        };
      }
      return state;
    case CLEAR_TRAY:
      device_uuid = action.payload?.deviceUuid;
      old = { ...state?.[device_uuid] };
      return {
        ...state,
        [device_uuid]: { ...old, trays: [] },
      };
    case FETCH_DEVICE_MAC_ADDRESS:
      return { ...state, fetchingMacAddressStatus: "fetching" };
    case FETCH_DEVICE_MAC_ADDRESS_RESPONSE:
      device_uuid = action.payload?.device_uuid;
      const macAddress = action.payload?.macAddress;
      old = { ...state?.[device_uuid] };
      return {
        ...state,
        [device_uuid]: { ...old, macAddress: macAddress },
        fetchingMacAddressStatus: "ready",
      };
    case FINISH_FETCH_MAC_ADDRESS:
      return {
        ...state,
        fetchingMacAddressStatus: "disconnected",
      };
    case RELOAD_SOCKET:
      return {};
    default:
      return state;
  }
};
