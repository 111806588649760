import React, { useState } from "react";
import { connect } from "react-redux";
import {
  displaySuccessNotification,
  fetchWifiNetworks,
  setupWPAWifi,
  cancelWifiAction,
  finishWifiSetup,
} from "../../../../../actions";
import {
  TableRow,
  TableContainer,
  TableCell,
  TableBody,
  Table,
  Paper,
  Typography,
  Box,
  CircularProgress,
  Fab,
} from "@mui/material";
import ForgetWifiTooltip from "./ForgetWifiTooltip";
import SyncIcon from "@mui/icons-material/Sync";
import ConfirmDialog from "../../../../ConfirmDialog";

const WifiTable = ({
  wifiState,
  setOpen,
  setSSID,
  wifiList,
  onChooseWifi,
  onForgetWifi,
  handleRescanWifiButtonPressed,
}) => {
  let [joinWifi, setJoinWifi] = useState(false);
  let [curWifi, setCurWifi] = useState(false);

  const chooseWifi = (wifi) => {
    setJoinWifi(true);
    setCurWifi(wifi);
  };

  return (
    <>
      <Box id={"wifi-credentials"} className={"w-full"}>
        <Box sx={{ width: "90%", textAlign: "center", m: "auto", mt: "2rem" }}>
          <Typography sx={{ display: "inline-block", mt: "10px" }}>
            Wifi Lists
          </Typography>
          {wifiState === "fetching" ? (
            <Box
              sx={{
                display: "inline-block",
                position: "relative",
                verticalAlign: "top",
              }}
            >
              <CircularProgress
                sx={{
                  ml: "2rem",
                  display: "inline-block",
                  position: "absolute",
                }}
                className={"p-2"}
              />
            </Box>
          ) : (
            <Box
              sx={{
                display: "inline-block",
                position: "relative",
                verticalAlign: "top",
              }}
            >
              <Fab
                id={"wifi-rescan-button"}
                color={"primary"}
                size={"small"}
                aria-label={"Rescan"}
                onClick={handleRescanWifiButtonPressed}
                sx={{
                  ml: "2rem",
                  display: "inline-block",
                  position: "absolute",
                }}
              >
                <SyncIcon />
              </Fab>
            </Box>
          )}
        </Box>
        {wifiList && (
          <TableContainer
            sx={{
              maxHeight: [400, 400, 400, 400, 550, 550],
              maxWidth: 500,
              m: "auto",
              mt: "2rem",
              border: 1,
              borderColor: "rgb(226, 232, 240)",
            }}
          >
            <Table>
              <TableBody>
                {wifiList?.map((wifi, i) => {
                  if (wifi.connected) {
                    return (
                      <TableRow hover role="checkbox" key={i}>
                        <TableCell align={"left"}>
                          {wifi.name}{" "}
                          <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                            (connected)
                          </span>
                        </TableCell>
                        <TableCell padding={"none"} align={"right"}>
                          <ForgetWifiTooltip
                            wifi={wifi.name}
                            onForgetWifi={onForgetWifi}
                          ></ForgetWifiTooltip>
                        </TableCell>
                      </TableRow>
                    );
                  } else if (wifi?.savedPassword) {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        key={i}
                        onDoubleClick={() => {
                          chooseWifi(wifi.name);
                        }}
                      >
                        <TableCell align={"left"}>
                          {wifi.name}{" "}
                          <span style={{ color: "rgba(0, 0, 0, 0.6)" }}>
                            (saved)
                          </span>
                        </TableCell>
                        <TableCell padding={"none"} align={"right"}>
                          <ForgetWifiTooltip
                            wifi={wifi.name}
                            onForgetWifi={onForgetWifi}
                          ></ForgetWifiTooltip>
                        </TableCell>
                      </TableRow>
                    );
                  }
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      key={i}
                      onDoubleClick={(event) => {
                        setSSID(wifi.name);
                        setOpen();
                      }}
                    >
                      <TableCell align={"left"}>{wifi.name}</TableCell>
                      <TableCell padding={"none"} align={"right"}></TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Box>
      <ConfirmDialog
        title={`Join WIFI ${curWifi}`}
        children={`Are you sure you want to Join WIFI ${curWifi}?`}
        open={joinWifi}
        setOpen={setJoinWifi}
        onConfirm={() => onChooseWifi(curWifi)}
      />
    </>
  );
};

export const mapStateToProps = (state) => {
  const { selectedDevice, ...devices } = state.devices;
  if (!selectedDevice) return {};
  const { displayName: deviceName } = devices?.[selectedDevice];
  const { status: wifiState, networks: wifiList } = state.wifi;
  // let list = [{ name: '123', connected: false, savedPassword: true },
  // { name: '123', connected: false, savedPassword: true },
  // { name: 'hello', connected: false, savedPassword: true },
  // { name: 'ohno', connected: false, savedPassword: true },
  // { name: 'kk', connected: true, savedPassword: true },
  // { name: '321321', connected: false, savedPassword: false },
  // { name: '4545', connected: false, savedPassword: false },
  // { name: '5454', connected: false, savedPassword: false },
  // { name: '', connected: false, savedPassword: false }]
  // let wifiList = _.sortBy(list, ['connected', 'savedPassword', 'name'], ['asc', 'desc', 'desc'])
  // console.log(wifiList)
  // let wifiList = networks?.filter(item => curConnectedWifi !== item).filter(item => !connectedWifis?.includes(item))
  // let connectedWifiList = connectedWifis.filter(item => curConnectedWifi !== item)
  return {
    selectedDevice,
    deviceName,
    wifiState,
    wifiList,
  };
};

export default connect(mapStateToProps, {
  displaySuccessNotification,
  fetchWifiNetworks,
  setupWPAWifi,
  cancelWifiAction,
  finishWifiSetup,
})(WifiTable);
