import React, { useEffect, useRef, useState } from "react";
import { MapContainer, Marker, ZoomControl, Popup } from "react-leaflet";
import { useMapEvents } from "../../../../node_modules/react-leaflet/lib/hooks";
import { Typography, Box, useMediaQuery } from "@mui/material";
import { useLocation } from "react-router-dom";
import MapCenterMarker from "./MapCenterMarker";
import * as esriV from "esri-leaflet-vector";
import L from "leaflet";

const markerFontSizeSx = {
  fontSize: [".75rem", ".75rem", ".75rem", "0.75rem", "1.125rem", "1.125rem"],
  m: "0 !important",
};

const TrapsMap = ({
  trapSites,
  onClickMarker,
  mapCenter,
  minZoom,
  maxZoom,
  zoom,
  worldCopyJump,
  onClickMap,
  trapSitePlacementMode,
  setOnTrapSiteMap,
  arcgisKey,
}) => {
  const lgMatches = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const [markers, setMarkers] = useState([]);
  const [map, setMap] = useState(null);
  const [OSMLayer, setOSMLayer] = useState(null);
  const [controlLayer, setControlLayer] = useState(null);
  const [satelliteLayer, setSatelliteLayer] = useState(null);
  const [satEnabled, setSatEnabled] = useState(false);
  const markerRef = useRef(null);
  const [markerInfo, setMarkerInfo] = useState({
    name: "",
    address: "",
    gps: [0, 0],
  });
  let markerRefs = {};
  const location = useLocation();
  const clickableMap =
    location.pathname.startsWith("/details/collection-site/edit") ||
    location.pathname.startsWith("/details/collection-site/create");
  const [newMarker, setNewMarker] = useState([]);
  const [mapInit, setMapInit] = useState(false);
  const [arcgisKeyInit, setArcgisKeyInit] = useState(false);

  //clean up default marker
  useEffect(() => {
    if (!clickableMap) {
      setMarkerInfo({ name: "", address: "", gps: [0, 0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clickableMap]);

  useEffect(() => {
    if (markerRef.current) {
      markerRef.current.openPopup();
    }
  }, [markerInfo, markerRef, newMarker]);

  useEffect(() => {
    if (map && (!mapInit || !arcgisKeyInit)) {
      let osmLayer = L.tileLayer(
        "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
        {
          maxZoom: 19,
          attribution: "© OpenStreetMap",
        }
      );
      setOSMLayer(osmLayer);
      if (arcgisKey) {
        const satLayer = new esriV.vectorBasemapLayer("ArcGIS:Imagery", {
          apiKey: arcgisKey,
          zoom: 18,
        });
        setSatelliteLayer(satLayer);
        const basemapLayers = {
          Default: osmLayer,
          Satellite: satLayer,
        };
        const lay = L.control.layers(basemapLayers, null, {
          collapsed: true,
          hideSingleBase: true,
        });
        setControlLayer(lay);
        lay.addTo(map);
        setArcgisKeyInit(true);
      }
      osmLayer.addTo(map);
      map.on("zoomend", () => {
        setSatEnabled(map.getZoom() >= 18);
      });
      setMapInit(true);
    }
  }, [map, mapInit, setArcgisKeyInit, arcgisKeyInit, arcgisKey]);

  useEffect(() => {
    if (map && mapInit && satelliteLayer && controlLayer) {
      if (satEnabled) {
        controlLayer.addBaseLayer(satelliteLayer, "Satellite");
      } else {
        controlLayer.removeLayer(satelliteLayer);
        map.removeLayer(satelliteLayer);
        map.addLayer(OSMLayer);
      }
    }
  }, [map, mapInit, satelliteLayer, controlLayer, satEnabled, OSMLayer]);

  //change mouse cursor to location icon
  useEffect(() => {
    if (map) {
      if (trapSitePlacementMode) {
        document.getElementById("map").style.cursor =
          "url(https://unpkg.com/leaflet@1.6.0/dist/images/marker-icon.png) 5 25,auto";
      } else {
        document.getElementById("map").style.cursor = "auto";
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trapSitePlacementMode]);

  useEffect(() => {
    if (map) {
      map.closePopup();
      map.setView(mapCenter, map.getZoom());

      //open up the popup for the current location
      for (const id in map?._layers) {
        const curLayer = map?._layers[id];
        const currentLatLng = curLayer?._latlng;
        if (
          mapCenter[0] === currentLatLng?.lat &&
          mapCenter[1] === currentLatLng?.lng
        ) {
          curLayer.openPopup();
        }
      }
    }
    // eslint-disable-next-line
  }, [map, mapCenter]);

  useEffect(() => {
    const m = Object.values(trapSites).reduce(
      (result, trapSite) =>
        addMarker(trapSite.id, trapSite, result, onClickMarker, lgMatches),
      []
    );
    setMarkers(m);
    // eslint-disable-next-line
  }, [trapSites, onClickMarker]);

  const ClickEvent = () => {
    useMapEvents({
      click: (e) => {
        let lat = e?.latlng?.lat || 0;
        let lng = e.latlng.lng || 0;
        if (trapSitePlacementMode) {
          onClickMap(lat, lng, zoom, setMarkerInfo);
        }
      },
      mouseout: () => {
        setOnTrapSiteMap(false);
      },
      mouseover: () => {
        setOnTrapSiteMap(true);
      },
    });
    return null;
  };

  const addMarker = (
    id,
    trapsite,
    result,
    onClickMarker = (s) => s,
    lgMatches
  ) => {
    if (!trapsite?.gpsLocation) return result;
    const { latitude, longitude } = trapsite?.gpsLocation;
    return [
      ...result,
      <Marker
        ref={(ref) => {
          if (ref !== null) {
            markerRefs[id] = ref;
          }
        }}
        key={id}
        position={[latitude, longitude]}
        onClick={() => onClickMarker(id)}
      >
        <Popup
          maxWidth={lgMatches ? 500 : 300}
          width={lgMatches ? 500 : 300}
          keepInView={false}
          autoClose={true}
        >
          <Box>
            {trapsite.displayName && trapsite.displayName !== "" && (
              <Typography sx={markerFontSizeSx}>
                {`Name: ${"\xa0".repeat(4)}${trapsite.displayName}`}
              </Typography>
            )}
            <Typography sx={markerFontSizeSx}>
              {`GPS: ${"\xa0".repeat(7)}${latitude?.toFixed(
                5
              )}, ${longitude?.toFixed(5)}`}
            </Typography>
            <Typography
              sx={{
                ...markerFontSizeSx,
                pl: ["50px", "50px", "50px", "50px", "77px", "77px"],
                textIndent: [
                  "-50px",
                  "-50px",
                  "-50px",
                  "-50px",
                  "-77px",
                  "-77px",
                ],
              }}
            >
              {`Address: ${trapsite.address}`}
            </Typography>
          </Box>
        </Popup>
      </Marker>,
    ];
  };

  useEffect(() => {
    const newDefaultMarker = addDefaultMarker(markerInfo, markerRef);
    setNewMarker(newDefaultMarker);
    // eslint-disable-next-line
  }, [markerInfo]);

  const addDefaultMarker = (markerInfo, ref) => {
    if (markerInfo?.gps[0] === 0) {
      return null;
    }
    return (
      <Marker position={markerInfo.gps} ref={ref}>
        <Popup maxWidth={lgMatches ? 500 : 300} width={lgMatches ? 500 : 300}>
          <Box>
            {markerInfo.name && markerInfo.name !== "" && (
              <Typography sx={markerFontSizeSx}>
                {`Name: ${"\xa0".repeat(4)}${markerInfo.name}`}
              </Typography>
            )}
            <Typography sx={markerFontSizeSx}>
              {`GPS: ${"\xa0".repeat(7)}${markerInfo?.gps[0]?.toFixed(
                5
              )}, ${markerInfo?.gps[1]?.toFixed(5)}`}
            </Typography>
            <Typography
              sx={{
                ...markerFontSizeSx,
                pl: ["50px", "50px", "50px", "50px", "77px", "77px"],
                textIndent: [
                  "-50px",
                  "-50px",
                  "-50px",
                  "-50px",
                  "-77px",
                  "-77px",
                ],
              }}
            >
              {`Address: ${markerInfo.address}`}
            </Typography>
          </Box>
        </Popup>
      </Marker>
    );
  };

  return (
    <>
      <MapContainer
        ref={setMap}
        center={mapCenter}
        minZoom={minZoom}
        maxZoom={maxZoom}
        zoom={zoom}
        worldCopyJump={worldCopyJump}
        className={"flex flex-1 z-10"}
        zoomControl={false}
        id="map"
      >
        <ZoomControl position="topleft" style={{ width: "100px" }} />
        <ClickEvent />
        {markers}
        {newMarker}
        <MapCenterMarker />
      </MapContainer>
    </>
  );
};

export default TrapsMap;
