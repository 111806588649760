import React, { useState } from "react";
import LogoutButton from "./LogoutButton";
import { useAuth0 } from "./../../../../providers";
import ConfirmDialog from "./../../../ConfirmDialog";

const Index = (props) => {
  const { handleClose } = props;
  const { logout } = useAuth0();
  const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);

  const onClick = () => {
    setShowLogoutConfirm(true);
  };

  const onConfirm = () => {
    logout();
    handleClose();
  };

  return (
    <div aria-describedby='Logout popup'>
      <ConfirmDialog
        title={"Logout"}
        children={"Are you sure you want to logout?"}
        open={showLogoutConfirm}
        setOpen={setShowLogoutConfirm}
        onConfirm={onConfirm}
      />
      <LogoutButton onClick={onClick} />
    </div>
  );
};

export default Index;
