import React, { useEffect, useState } from "react";
import {
    TableContainer,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Box,
    useMediaQuery,
    Typography,
} from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Draggable from "react-draggable";

const DevicesTable = ({
    devicesVectorTypes
}) => {
    const [expand, setExpand] = useState(false);
    const [pos, setPos] = useState([25, 25]);
    const lgMatches = useMediaQuery((theme) => theme.breakpoints.up("lg"));
    const hdMatches = useMediaQuery((theme) => theme.breakpoints.up("hd"));
    const xlMatches = useMediaQuery((theme) => theme.breakpoints.up("xl"));


    useEffect(() => {
        if (xlMatches) {
            setPos([45, 10]);
        } else if (lgMatches) {
            setPos([45, 10]);
        } else {
            setPos([45, 10]);
        }
    }, [lgMatches, hdMatches, xlMatches]);

    return (
        <Draggable
            handle='.handle'
        >
            <Box
                style={{
                    position: "absolute",
                    zIndex: 150,
                    cursor: 'auto'
                }}
                sx={{
                    width: expand ? ['360px', '360px', '360px', '460px', '460px', '460px'] : "150px",
                    top: `${pos[0]}px`,
                    left: `${pos[1]}px`,
                    bgcolor: "#fff",
                    borderRadius: "0.75rem",
                    border: "solid 1px rgb(33, 150, 243)",
                }}
            >
                <Box
                    className="handle"
                    style={{
                        cursor: 'move'
                    }}
                    sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        px: "1rem",
                        py: ".5rem",
                        borderBottom: expand ? "1px solid black" : "",
                    }}
                >
                    <Typography>Devices</Typography>
                    {expand ? (
                        <RemoveIcon
                            onClick={() => setExpand(!expand)}
                            style={{
                                cursor: "pointer",
                            }}
                        />
                    ) : (
                        <AddIcon
                            onClick={() => setExpand(!expand)}
                            style={{
                                cursor: "pointer",
                            }}
                        />
                    )}
                </Box>
                {expand && (
                    <Box>
                        <TableContainer
                            sx={{
                                width: ['350px',
                                    '350px',
                                    '350px',
                                    '450px',
                                    '450px',
                                    '450px',
                                ],
                                mx: 'auto',
                                maxHeight: '350px'
                            }}
                        >
                            <Table sx={{ minWidth: 300 }} aria-label="simple table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Display Name</TableCell>
                                        <TableCell align="center">Algorithm</TableCell>
                                        <TableCell align="center">version</TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {devicesVectorTypes.map((row) => (
                                        <TableRow
                                            key={row?.displayName}
                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                        >
                                            <TableCell component="th" scope="row" sx={{
                                                whiteSpace: 'nowrap'
                                            }}>
                                                {row?.displayName}
                                            </TableCell>
                                            <TableCell align="right" sx={{
                                                whiteSpace: 'nowrap'
                                            }}>{row?.vectorType}</TableCell>
                                            <TableCell align="right" sx={{
                                                whiteSpace: 'nowrap'
                                            }}>{row?.algVersion}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>

                            </Table>
                        </TableContainer>

                    </Box>
                )}
            </Box>
        </Draggable >
    );
};

export default DevicesTable;

