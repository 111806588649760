import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraIcon from "@mui/icons-material/Camera";
import { Typography, Fab, CircularProgress, Box, Grid } from "@mui/material";
import RemoteSubmitButton from "../../../Forms/FormSubmitButton";
import ConfirmDialog from "./../../../ConfirmDialog";
import {
  renderInput,
  RenderSelect,
  renderAutocomplete,
  RenderDatePicker,
} from "../../../Forms/FormFields";
import { THUMB_H, THUMB_W } from "../../../../utils/const";
import ResponsiveButton from "../../../Forms/ResponsiveButton";
import { timeToLocale } from "../../../../utils/timeConversion";
import { RenderAutocompleteMultiple } from "../../../Forms/renderAutocompleteMultiple";
import SpecimenBBox from "./SpecimenBBox";
import useWindowSize from "../../../../lib/useWindowSizeHook";
// import AutoSave from "../../SideBar/DetailsRouter/SpecimenDetails/AutoSave";
import AutoSave from "./AutoSave";
import { Form, Field } from "react-final-form";
import {
  checkCommaAndBackSlash,
  checkCommaAndBackSlashOnTags,
} from "../../../../lib/validationHelper";
import { createFilterOptions } from "@mui/material/Autocomplete";

const TypographySx = {
  my: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", "0.5rem", "0.5rem"],
  fontSize: ["0.85rem", "0.85rem", "0.85rem", "0.85rem", "1.25rem", "1.25rem"],
};

const captureOtherSideSX = {
  fontSize: ["0.55rem", "0.55rem", "0.6rem", "0.6rem", "0.875rem", "0.875rem"],
  "& .MuiSvgIcon-root": {
    fontSize: [
      "0.55rem",
      "0.55rem",
      "0.83rem !important",
      "0.83rem !important",
      "1.25rem !important",
      "1.25rem !important",
    ],
  },
};

const TrayViewer = ({
  handleRetakeTray,
  handleDeleteTray,
  handleCaptureOtherSide,
  handleSwitchImage,
  disableSwitchButton,
  disableCaptureOtherSide,
  handleFinish,
  disableRetakeSide,
  trapSites,
  trayId,
  trayDisplayName,
  trayCaptureTimeStamp,
  specimens,
  traySideMsg,
  specimenOnDisplay,
  loading,
  trayImage,
  handleChangeMetadata,
  metadataErrorDialogOpts,
  trapTypeAutoCompleteOptions,
  disableFinish,
  pathname,
  handleSpecimenJump,
  timeZone,
  metadata,
}) => {
  const [showRetakeConfirm, setShowRetakeConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);
  const hiddenViewElement = pathname === "/details/tray/capture" ? false : true;
  const headerButtonStyle = {
    color: "#DAF4FE",
    background: "rgba(218, 244, 254, 0.1)",
  };

  //calculate the modal factor base on window height
  const windowSize = useWindowSize();
  const [trayImageFactor, setImageFactor] = useState(0.66);
  const computeTrayImageFactor = useCallback(() => {
    if (windowSize.height > 1300) {
      setImageFactor(1.44);
    } else if ((windowSize.height <= 1300) & (windowSize.height > 1000)) {
      setImageFactor(1.25);
    } else if ((windowSize.height <= 1000) & (windowSize.height > 800)) {
      setImageFactor(1);
    } else {
      setImageFactor(0.66);
    }
  }, [windowSize]);
  useEffect(() => {
    computeTrayImageFactor();
  }, [windowSize, computeTrayImageFactor]);

  const phraseTimestamp = (trayCaptureTimeStamp) => {
    let customFormat = "M/DD/YYYY, h:mm:ss A z";
    return timeToLocale(trayCaptureTimeStamp, { timeZone, customFormat });
  };

  return (
    <Form
      onSubmit={() => {}}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      initialValues={metadata}
      validate={(values) => {
        const errors = {};
        // if (values.well && values.well?.length > 0 && (values.plate?.length <= 0 || !values.plate)) {
        //   errors.plate = 'Must have a plate number'
        // }
        return errors;
      }}
      render={(form, change) => (
        <div className={"flex flex-col"} id={"specimen-details"}>
          <AutoSave
            debounce={0}
            trayId={trayId}
            save={(value) => {
              let isChanged = value?.id ? false : true;
              handleChangeMetadata(value, isChanged);
            }}
          />
          <Box className={"flex flex-1 flex-col"} id={"capture-tray"}>
            <form id={"trap-info-form"}>
              <div
                className={"flex flex-row bg-transparent justify-between "}
                id={"tray-header"}
              >
                <div className={"flex flex-col-reverse"} id={"retake-image"}>
                  <ResponsiveButton
                    className={"round-sm block"}
                    style={{
                      ...headerButtonStyle,
                      visibility: hiddenViewElement ? "hidden" : "visible",
                    }}
                    onClick={() => setShowRetakeConfirm(true)}
                    disabled={disableRetakeSide}
                  >
                    <AutorenewIcon style={{ color: "#DAF4FE" }} />
                    Retake
                  </ResponsiveButton>
                  <ConfirmDialog
                    title={"Retake tray image?"}
                    children={
                      "The new image will overwrite the current image. Make sure the tray is in the same orientation."
                    }
                    open={showRetakeConfirm}
                    setOpen={setShowRetakeConfirm}
                    onConfirm={handleRetakeTray}
                    id={"retake-tray-confirm-dialog"}
                  />
                </div>

                <Typography className={"text-white"} sx={TypographySx}>
                  {trayDisplayName}
                  {traySideMsg}
                </Typography>

                <div
                  className={"flex flex-col-reverse"}
                  id={"switch-tray-side"}
                >
                  <ResponsiveButton
                    style={headerButtonStyle}
                    onClick={handleSwitchImage}
                    disabled={loading || disableSwitchButton}
                    sx={{
                      maxHeight: "36.5px",
                      display: disableSwitchButton ? "none" : "block",
                    }}
                  >
                    <SwapHorizIcon />
                  </ResponsiveButton>
                </div>
              </div>
              <div className={"bg-white"}>
                <div className={"flex relative flex-1"} id={"tray-image"}>
                  <Box
                    className={"flex justify-center items-center"}
                    style={{
                      height: `${THUMB_H * trayImageFactor}px`,
                      width: `${THUMB_W * trayImageFactor}px`,
                    }}
                  >
                    {!trayImage ? (
                      <CircularProgress size={"5rem"} id={"loading-tray"} />
                    ) : (
                      <img
                        src={trayImage}
                        width={trayImageFactor * THUMB_W}
                        height={trayImageFactor * THUMB_H}
                        alt={"tray"}
                        data-testid="tray-image"
                      />
                    )}
                    {specimens &&
                      /*eslint array-callback-return: ["error", { allowImplicit: true }]*/
                      specimens.map((s, i) => {
                        if (Array.isArray(s?.bbox)) {
                          const h = `${
                            (s.bbox[3] - s.bbox[1]) * trayImageFactor
                          }px`;
                          const w = `${
                            (s.bbox[2] - s.bbox[0]) * trayImageFactor
                          }px`;
                          const topX = `${s.bbox[0] * trayImageFactor}px`;
                          const topY = `${s.bbox[1] * trayImageFactor}px`;
                          const border =
                            i === specimenOnDisplay
                              ? "3px solid #428BCA"
                              : "0px solid #428BCA";

                          return (
                            <SpecimenBBox
                              s={s}
                              i={i}
                              w={w}
                              h={h}
                              topX={topX}
                              topY={topY}
                              border={border}
                              handleJump={() => handleSpecimenJump(i)}
                            ></SpecimenBBox>
                          );
                        }
                        return;
                      })}

                    <Box
                      sx={{
                        position: "absolute",
                        bottom:
                          form?.values?.tags?.length > 0
                            ? [
                                "-25px",
                                "-25px",
                                "-25px",
                                "-26px",
                                "-33px",
                                "-33px",
                              ]
                            : "-5px",
                        left: "12px",
                        paddingRight: "20px",
                        width: "100%",
                      }}
                    >
                      <Field
                        // multi
                        label="Tray Tags"
                        name={`tags`}
                        chipColor="#ab47bc"
                        shadowLabel={false}
                        outlined={false}
                        inputProps={{
                          id: `tags`,
                          style: {
                            width: "100%",
                            minWidth: "100%",
                          },
                        }}
                        component={RenderAutocompleteMultiple}
                        // options={tagOptions || []}
                        fieldValue={form?.values?.tags}
                        onChange={(event, value) => {
                          form.form.mutators.setValue("tags", [...value]);
                        }}
                        onBlur={(e) => e.preventDefault()}
                        multiple={true}
                        validate={checkCommaAndBackSlashOnTags}
                      />
                    </Box>
                  </Box>
                </div>
                <div className={"flex flex-1 flex-col p-2"} id={"trap-info"}>
                  <Typography
                    className={"text-gray-600"}
                    sx={{
                      fontSize: [
                        ".5rem",
                        ".5rem",
                        ".5rem",
                        ".5rem",
                        "1.15rem",
                        "1.25rem",
                      ],
                      transform:
                        form?.values?.tags?.length > 0
                          ? [
                              "translateY(16px)",
                              "translateY(16px)",
                              "translateY(16px)",
                              "translateY(16px)",
                              "translateY(16px)",
                              "translateY(16px)",
                            ]
                          : "translateY(0px)",
                    }}
                  >
                    Imaging Time:{" "}
                    {trayCaptureTimeStamp !== "" &&
                      phraseTimestamp(trayCaptureTimeStamp)}
                  </Typography>
                  <div className={"flex flex-1 justify-around py-4 px-6"}>
                    <Grid container spacing={{ xs: 1, md: 3, lg: 5 }}>
                      <Grid item sm={6}>
                        <Box
                          className={"flex flex-1 flex-col"}
                          sx={{
                            "& >div:first-of-type": {
                              height: [
                                "60px",
                                "60px",
                                "60px",
                                "60px",
                                "71px",
                                "71px",
                              ],
                            },
                            ".MuiAutocomplete-root + .MuiFormControl-root": {
                              mt: [
                                "0.5rem",
                                "0.5rem",
                                "0.5rem",
                                "0.5rem",
                                "1rem",
                                "1rem",
                              ],
                            },
                          }}
                        >
                          <Field
                            key="trapType"
                            label="Collection Method"
                            name="trapType"
                            inputProps={{
                              id: "trap-type-field",
                              "data-testid": "collection-method",
                            }}
                            options={trapTypeAutoCompleteOptions}
                            disabled={loading}
                            component={renderAutocomplete}
                            validate={checkCommaAndBackSlash}
                          />
                          <Field
                            key="trapSiteId"
                            data-testid="collection-site"
                            id="Collection Site"
                            label="Collection Site"
                            name="trapSiteId"
                            autocompleteProps={{
                              id: "trap-site-field",
                              // style: { width: "100%" },
                            }}
                            disabled={loading}
                            options={trapSites || []}
                            getOptionLabel={(value) => {
                              return (
                                trapSites?.find(
                                  (option) => option?.id === value
                                )?.displayName || ""
                              );
                            }}
                            isOptionEqualToValue={(option, value) => {
                              return option?.id === value;
                            }}
                            getOptionKey={(option) => {
                              return option?.id || "";
                            }}
                            filterOptions={createFilterOptions({
                              matchFrom: "any",
                              stringify: (option) => option.displayName,
                            })}
                            component={RenderSelect}
                          />
                        </Box>
                      </Grid>
                      <Grid item sm={6}>
                        <Box
                          className={"flex flex-1 flex-col"}
                          sx={{
                            "& >div:first-of-type": {
                              height: [
                                "60px",
                                "60px",
                                "60px",
                                "60px",
                                "71px",
                                "71px",
                              ],
                            },
                            ".MuiFormControl-root + .MuiFormControl-root": {
                              mt: [
                                "0.5rem",
                                "0.5rem",
                                "0.5rem",
                                "0.5rem",
                                "1rem",
                                "1rem",
                              ],
                            },
                          }}
                        >
                          <Field
                            key="captureDate"
                            label="Specimen Capture Date"
                            name="captureDate"
                            disabled={loading}
                            component={RenderDatePicker}
                            inputProps={{
                              "data-testid": "capture-date",
                            }}
                            setHasErrorsFlag={setHasValidationErrors}
                            cusMinDate="1900-01-01"
                            trayId={trayId}
                            handleChange={() => {}}
                          />
                          <Field
                            key="notes"
                            data-testid="notes"
                            label="Notes"
                            name="notes"
                            inputProps={{
                              id: "tray-notes",
                              multiline: true,
                              maxRows: 5,
                              fullWidth: true,
                            }}
                            testId="notes"
                            disabled={loading}
                            handleChange={() => {}}
                            component={renderInput}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </div>
                  <div
                    className={"flex flex-row justify-between pb-2 px-6 mr-4"}
                  >
                    <ResponsiveButton
                      onClick={() => setShowDeleteConfirm(true)}
                      disabled={loading}
                      id={"delete-tray"}
                      sx={{
                        color: "status.danger",
                      }}
                    >
                      <DeleteIcon />
                      DELETE
                    </ResponsiveButton>

                    <ConfirmDialog
                      title={"Delete tray?"}
                      children={
                        "All tray data will be deleted, including its specimens data and images"
                      }
                      open={showDeleteConfirm}
                      setOpen={setShowDeleteConfirm}
                      onConfirm={handleDeleteTray}
                      id={"delete-tray-confirm-dialog"}
                    />
                    <ConfirmDialog
                      {...metadataErrorDialogOpts}
                      title={"You may still have unsaved changes"}
                      children={"Continue anyway?"}
                      id={"metadata-error-confirm-dialog"}
                    />
                    <Fab
                      onClick={handleCaptureOtherSide}
                      variant={"extended"}
                      color={"primary"}
                      size={"small"}
                      disabled={disableCaptureOtherSide}
                      id={"capture-other-side"}
                      sx={captureOtherSideSX}
                      style={{
                        visibility: hiddenViewElement ? "hidden" : "visible",
                      }}
                    >
                      <CameraIcon className={"mr-1"} />
                      CAPTURE OTHER SIDE
                    </Fab>
                    <RemoteSubmitButton
                      id={"upload-tray-metadata"}
                      disabled={hasValidationErrors || disableFinish}
                      onClick={() => handleFinish()}
                    >
                      Finish
                    </RemoteSubmitButton>
                  </div>
                </div>
              </div>
            </form>
          </Box>
        </div>
      )}
    ></Form>
  );
};

export default connect()(TrayViewer);
