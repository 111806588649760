import SocketIOClient from "./SocketIoClient";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ([socketUrl, getToken]) =>
  (store) => {
    const client = new SocketIOClient(store, socketUrl, getToken);
    return (next) => (action) => {
      if (action.socket && action.socket.send) {
        if (!action.socket.keepSocket) {
          let { socket, ...action2 } = action;
          action = action2;
        }
        client.send(action);
      }
      // if (action.socket && action.socket?.organizationRefresh) {
      //   client.organizationRefresh({ payload: { organization: action.socket?.organization } })
      // }
      return next(action);
    };
  };
