import {
  FETCH_USER_AUTOFILL_SETTINGS,
  UPDATE_USER_AUTOFILL_SETTINGS,
  UPDATE_USER_TIMEZONE_SETTINGS,
  FETCH_USER_SETTINGS,
  UPDATE_USER_ADDRESS_SEARCH_RADIUS_SETTINGS,
  UPDATE_USER_MAP_CENTER_SETTINGS,
  UPDATE_USER_ALGORITHM_CONFIDENCE_SETTINGS,
  SET_MAP_CENTER,
  FETCH_ORGANIZATIONS,
  UPDATE_SUPER_USER_ORGANIZATION,
  // RELOAD_SOCKET,
  FETCH_USERS_BY_ORG,
  APPROVE_USER,
  DELETE_USER,
} from "./types";

import {
  displaySuccessNotification,
  displayErrorNotification,
} from "./notifications";

export const updateUserAutofillSettings =
  (sendRequest, settings) => async (dispatch) => {
    dispatch({ type: UPDATE_USER_AUTOFILL_SETTINGS, payload: settings });
    await sendRequest("patch")({
      url: `/user/autofill-settings`,
      devPort: 5004,
      data: settings,
    });
  };

export const fetchUserAutofillSettings =
  (sendRequest, settings) => async (dispatch) => {
    dispatch({ type: FETCH_USER_AUTOFILL_SETTINGS, payload: settings });
    const response = await sendRequest("get")({
      url: `/user/autofill-settings`,
      devPort: 5004,
    });
    dispatch({ type: FETCH_USER_AUTOFILL_SETTINGS, payload: response.data });
  };

export const updateUserTimeZoneSettings = (timeZone) => async (dispatch) => {
  dispatch({
    type: UPDATE_USER_TIMEZONE_SETTINGS,
    payload: { timeZone: timeZone },
  });
};

export const updateUserAddressSearchRadiusSettings =
  (addressSearchRadius) => async (dispatch) => {
    dispatch({
      type: UPDATE_USER_ADDRESS_SEARCH_RADIUS_SETTINGS,
      payload: { addressSearchRadius },
    });
  };

export const updateUserAlgConfidenceSettings =
  (algConfidence) => async (dispatch) => {
    dispatch({
      type: UPDATE_USER_ALGORITHM_CONFIDENCE_SETTINGS,
      payload: { algConfidence },
    });
  };

export const updateUserMapCenterSettings = (mapCenter) => async (dispatch) => {
  dispatch({ type: UPDATE_USER_MAP_CENTER_SETTINGS, payload: { mapCenter } });
  let mapLocation = mapCenter?.split(",")?.map(parseFloat);

  dispatch({ type: SET_MAP_CENTER, payload: mapLocation });
};

export const fetchUserSettings =
  (sendRequest, settings) => async (dispatch) => {
    const response = await sendRequest("get")({
      url: `/user/user-settings`,
      devPort: 5004,
    });
    dispatch({ type: FETCH_USER_SETTINGS, payload: response.data });
  };

export const fetchOrganizations = (sendRequest) => async (dispatch) => {
  const response = await sendRequest("get")({
    url: "/organization/organizations",
    devPort: 50057,
  });
  dispatch({ type: FETCH_ORGANIZATIONS, payload: response.data });
};

export const updateSuperUserOrganization =
  (sendRequest, organizationUuid, getToken) => async (dispatch) => {
    dispatch({
      type: UPDATE_SUPER_USER_ORGANIZATION,
      payload: { organizationUuid },
    });
    const res = await sendRequest("patch")({
      url: `/user/superuser/organization`,
      devPort: 5004,
      data: { organization: organizationUuid },
    }).catch((err) => {
      console.log("Error in PATCH /user/superuser/organization");
    });
    console.log(res);
    if (res?.status === 200) {
      await getToken(true);
      window.location.reload(false);
      // dispatch({
      //   type: RELOAD_SOCKET,
      //   socket: { organizationRefresh: true, organization: organizationUuid },
      // });
    }
  };

export const getUsersByOrg =
  (sendRequest, organization) => async (dispatch) => {
    const res = await sendRequest("get")({
      url: `/user/superuser/organization/${organization}/users`,
      devPort: 5004,
    }).then((res) =>
      res?.status === 200
        ? res.data.reduce(
          (curr, user) => ({ ...curr, [user.user_id]: user }),
          {}
        )
        : null
    );
    if (res) {
      dispatch({
        type: FETCH_USERS_BY_ORG,
        payload: { orgUsers: { [organization]: res } },
      });
    }
  };

export const approveUser =
  (sendRequest, organization, userId, mapCenter, userName) =>
    async (dispatch) => {
      const res = await sendRequest("patch")({
        url: `/user/superuser/organization/${organization}/user/${userId}/approve`,
        devPort: 5004,
        data: {
          mapCenter,
        },
      }).catch((err) => {
        dispatch(displayErrorNotification("Failed to approve user..."));
      });
      if (res?.status === 200) {
        dispatch({
          type: APPROVE_USER,
          payload: {
            organization,
            userId,
            metadata: { map_center: mapCenter, organization },
          },
        });
        dispatch(
          displaySuccessNotification(
            `Successfully approved ${userName} for ${organization}`
          )
        );
      }
    };

export const deleteUser =
  (sendRequest, organization, userId, userName) => async (dispatch) => {
    const res = await sendRequest("delete")({
      url: `/user/superuser/${userId}`,
      devPort: 5004,
    }).catch((err) => {
      dispatch(displayErrorNotification(`Failed to delete ${userName}`));
    });
    if (res?.status === 200) {
      dispatch({
        type: DELETE_USER,
        payload: {
          organization,
          userId,
        },
      });
      dispatch(displaySuccessNotification(`Successfully deleted ${userName}`));
    }
  };
