import React from "react";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { Box } from "@mui/system";

const OrganizationSelector = ({
  organizationList,
  selectedDevice,
  handleChangeOrganization,
  loading,
  disabled,
}) => {
  const orgList = organizationList?.map(({ uuid, display_name: name }) => {
    return (
      <MenuItem
        value={uuid}
        key={uuid}
        className={"w-full text-center"}
        sx={{
          fontSize: [".75rem", ".75rem", ".75rem", ".75rem", "1rem", "1rem"],
        }}
      >
        {name}
      </MenuItem>
    );
  });
  return (
    <Box
      className={"flex z-20"}
      id={"organization-selector"}
      sx={{
        position: "absolute",
        bottom: "0.5rem",
        left: "50%",
        transform: "translate(-50%, 0)",
      }}
    >
      <FormControl
        variant="standard"
        sx={{
          width: ["10rem", "10rem", "16rem", "16rem", "20rem", "24rem"],
        }}
      >
        <Select
          className={"bg-white shadow-xl flex-1 top-30 text-center first:p-0"}
          label="Select Device"
          id="idtool-select"
          value={selectedDevice}
          disabled={disabled}
          onChange={handleChangeOrganization}
          sx={{
            fontSize: [".75rem", ".75rem", ".75rem", ".75rem", "1rem", "1rem"],
          }}
        >
          <MenuItem
            sx={{
              height: [
                "1.85rem",
                "1.85rem",
                "1.85rem",
                "1.85rem",
                "2.1rem",
                "2.1rem",
              ],
            }}
            value={"none"}
            key={"1"}
          >
            <p></p>
          </MenuItem>
          {orgList}
        </Select>
        <Box
          sx={{
            position: "fixed",
            right: "-10%",
          }}
        >
          {loading ? (
            <CircularProgress
              id="device-selector-loading-circle"
              sx={{
                cx: [0, 0, 0, 0, 0, 0],
                cy: [0, 0, 0, 0, 0, 0],
                width: [
                  "25px !important",
                  "25px !important",
                  "25px !important",
                  "25px !important",
                  "30px !important",
                  "30px !important",
                ],
                height: [
                  "25px !important",
                  "25px !important",
                  "25px !important",
                  "25px !important",
                  "30px !important",
                  "30px !important",
                ],
              }}
            />
          ) : null}
        </Box>
      </FormControl>
    </Box>
  );
};

export default OrganizationSelector;
