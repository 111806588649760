import React from "react";
import WifiIcon from "@mui/icons-material/Wifi";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";

const SetupWifiButton = (props) => {
  const { onClick } = props;
  return (
    <MenuItem color="primary" onClick={onClick}>
      <WifiIcon />
      <Typography sx={{ pl: 1 }}>Connect Device</Typography>
    </MenuItem>
  );
};

export default SetupWifiButton;
