import React from "react";
import SuperUserPanelButton from "./SuperUserPanelButton";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../AppRouter/routes";

const Index = (props) => {
  const { handleClose } = props;
  let history = useHistory();

  const onClick = () => {
    handleClose();
    history.push(ROUTES.superUserPanel);
  };

  return (
    <div>
      <SuperUserPanelButton onClick={onClick} />
    </div>
  );
};

export default Index;
