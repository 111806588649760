import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import DataTable from "../../../DataTable/DataTable";
import { useWebServer } from "../../../../providers/WebServerProvider";
import { useHistory } from "react-router-dom";
import { fetchTrays } from "../../../../actions";
import { timeToLocale } from "../../../../utils/timeConversion";
import ROUTES from "../../../AppRouter/routes";
import { Typography } from "@mui/material";
import { phraseDownloadDataFilter } from "../../../../utils/dataDownload";

const options = {
  allowCreate: false,
  allowClick: true,
};

const TrayList = ({
  trays,
  count,
  toolId,
  selectedDevice,
  page,
  pageSize,
  order,
  orderBy,
  fetchTrays,
  timeZone,
  generalFilters,
}) => {
  // const [firstSet, setFirstSet] = useState(true);
  const { sendRequest } = useWebServer();
  const [traysData, setTraysData] = useState([]);
  const history = useHistory();

  const columns = [
    {
      field: "displayName",
      label: "Id",
      isSortable: false,
      align: "left",
      sx: "",
    },
    {
      field: "createdOn",
      label: "Created At",
      isSortable: true,
      align: "left",
      formatFn: (s) => (s ? timeToLocale(s, { timeZone }) : s),
      sx: {},
    },
    {
      field: "trapType",
      label: "Collection Method",
      isSortable: false,
      align: "left",
      sx: {
        maxWidth: ["4rem", "4rem", "6rem", "6rem", "6rem", "10rem"],
        overFlow: "hidden",
      },
    },
    {
      field: "trapSiteV1.displayName",
      label: "Collection Site",
      isSortable: false,
      align: "right",
      sx: { maxWidth: ["4rem", "4rem", "6rem", "10rem", "10rem", "10rem"] },
    },
  ];

  const handleClickTray = (rowData) => {
    history.push(ROUTES.viewTray.replace(":id", rowData.id));
  };

  // const handleFetchTrays = useCallback(async () => {
  //request the next page in advance
  // if (toolId) {
  //   const trayOrderBy = orderBy === "id" ? "tray.id" : orderBy;
  //   let filters = phraseDownloadDataFilter(generalFilters, timeZone)
  //   await fetchTrays(
  //     toolId,
  //     { page, pageSize, orderBy: trayOrderBy, order, ...filters },
  //     sendRequest,
  //     selectedDevice
  //   );
  //   if ((count || 0) > (page + 1) * pageSize)
  //     fetchTrays(
  //       toolId,
  //       { page: page + 1, pageSize, orderBy: trayOrderBy, order, ...filters },
  //       sendRequest,
  //       selectedDevice
  //     );
  // }
  // }, [count, fetchTrays, order, orderBy, page, pageSize, selectedDevice, sendRequest, toolId]);

  useEffect(() => {
    if (toolId) {
      let filters = phraseDownloadDataFilter(generalFilters, timeZone);
      const trayOrderBy = orderBy === "displayId" ? "tray.id" : orderBy;
      if ((count || 0) > (page + 1) * pageSize)
        fetchTrays(
          toolId,
          { page: page + 1, pageSize, orderBy: trayOrderBy, order, ...filters },
          sendRequest,
          selectedDevice
        );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    selectedDevice,
    count,
    fetchTrays,
    order,
    orderBy,
    page,
    pageSize,
    sendRequest,
    toolId,
  ]);

  // useEffect(() => {
  //   firstSet ? setFirstSet(false) : handleFetchTrays();
  // }, [page, pageSize, orderBy, order, firstSet, handleFetchTrays]);

  useEffect(() => {
    setTraysData(
      _.chain(trays)
        .filter({ valid: true })
        .orderBy([orderBy === "tray.id" ? "displayId" : orderBy], [order])
        .slice(page * pageSize, Math.min(count, (page + 1) * pageSize))
        .value()
    );
  }, [trays, count, page, pageSize, orderBy, order]);

  return selectedDevice ? (
    <div id={"trays"}>
      <DataTable
        dataList={traysData}
        total={count || 0}
        tableName={"Tray"}
        columns={columns}
        onClickRow={handleClickTray}
        options={options}
      />
    </div>
  ) : (
    <Typography
      className={"text-center my-auto"}
      sx={{
        fontSize: ["1.15rem", "1.15rem", "1.15rem", "1.15rem", "2rem", "2rem"],
      }}
    >
      Select tool first
    </Typography>
  );
};

export const mapStateToProps = (state) => {
  const { page, pageSize, order, orderBy } = state?.settings?.Tray;
  const { selectedDevice } = state.devices;
  const { timeZone = "AUTO" } = state.user;

  if (!selectedDevice)
    return {
      trays: [],
      count: 0,
      selectedDevice,
      page,
      pageSize,
      order,
      orderBy,
    };
  const { trays: trayList, id } = state.devices[selectedDevice];

  const { count, ...trays } = _.isPlainObject(trayList) ? trayList : {};
  return {
    trays: _.isPlainObject(trays) ? Object.values(trays) : null,
    count,
    toolId: id,
    selectedDevice,
    page,
    pageSize,
    order,
    orderBy,
    timeZone,
    generalFilters: state.data?.generalFilters || {},
  };
};

export default connect(mapStateToProps, { fetchTrays })(TrayList);
