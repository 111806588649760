import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import SettingsRemoteIcon from "@mui/icons-material/SettingsRemoteTwoTone";
import Paper from "@mui/material/Paper";
import ModalContainer from "../ModalContainer";
import BluetoothSetup from "./BluetoothSetup";
import WifiSetup from "./WifiSetup";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import {
  Typography,
  Box,
  Switch,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  ListSubheader,
} from "@mui/material";

const MaterialUISwitch = styled(Switch)(({ theme }) => ({
  width: 62,
  height: 34,
  padding: 7,
  "& .MuiSwitch-switchBase": {
    margin: 1,
    padding: 0,
    transform: "translateX(6px)",
    "&.Mui-checked": {
      color: "#fff",
      transform: "translateX(22px)",
      "& .MuiSwitch-thumb:before": {
        backgroundImage:
          "url('https://www.svgrepo.com/download/150129/bluetooth.svg')",
      },
      "& + .MuiSwitch-track": {
        opacity: 1,
        backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
      },
    },
  },
  "& .MuiSwitch-thumb": {
    backgroundColor: theme.palette.mode === "dark" ? "#003892" : "#001e3c",
    width: 32,
    height: 32,
    "&::before": {
      content: "''",
      position: "absolute",
      width: "100%",
      height: "100%",
      left: 0,
      top: 0,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
      backgroundImage:
        "url('https://www.svgrepo.com/download/150129/bluetooth.svg')",
    },
  },
  "& .MuiSwitch-track": {
    opacity: 1,
    backgroundColor: theme.palette.mode === "dark" ? "#8796A5" : "#aab4be",
    borderRadius: 20 / 2,
  },
}));

const DeviceSetup = ({ selectedDevice, deviceState }) => {
  const [bluetoothModeEnabled, setBluetoothModeEnabled] = useState(false);
  const useBluetoothMode = bluetoothModeEnabled || deviceState === "OFF";

  const unselectedMessage = (msg) => {
    return (
      <Paper
        className={
          "flex flex-col justify-center items-center bg-white rounded-xl border-4 border-transparent transition-opacity duration-1000"
        }
        sx={{
          height: ["560px", "560px", "560px", "560px", "786px", "786px"],
          width: ["100vw", "544px", "544px", "544px", "824px", "824px"],
        }}
      >
        <Typography
          className={" text-gray-600"}
          sx={{
            fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
          }}
        >
          {msg}
        </Typography>
        <Box
          sx={{
            mt: ["2rem", "2rem", "2rem", "2rem", "4rem", "4rem"],
            "& svg": {
              height: ["100px", "100px", "100px", "100px", "200px", "200px"],
            },
          }}
        >
          <SettingsRemoteIcon style={{ fontSize: 200 }} />
        </Box>
      </Paper>
    );
  };

  const getDisplayed = () => {
    if (selectedDevice && ["IDLE", "OFF"].includes(deviceState)) {
      if (useBluetoothMode) {
        return <BluetoothSetup />;
      } else {
        return <WifiSetup />;
      }
    }
    return unselectedMessage(
      "Please select a valid device to continue with setup..."
    );
  };

  return (
    <>
      <ModalContainer>
        <Paper
          sx={{
            height: ["560px", "560px", "560px", "560px", "786px", "786px"],
            width: ["100vw", "544px", "544px", "544px", "824px", "824px"],
          }}
          className={
            "bg-white rounded-xl border-4 border-transparent m-auto transition-opacity duration-1000"
          }
          id={"bluetooth-scan"}
        >
          <span>
            <List
              sx={{
                zIndex: 10,
                width: "2rem",
                float: "right",
                margin: "1rem",
              }}
            >
              <ListItem>
                <ListItemIcon>
                  <BluetoothIcon />
                </ListItemIcon>

                <ListItemSecondaryAction>
                  <Switch
                    onChange={() => {}}
                    disabled={deviceState !== "IDLE"}
                    edge="end"
                    checked={useBluetoothMode}
                    onClick={() =>
                      setBluetoothModeEnabled(!bluetoothModeEnabled)
                    }
                  />
                </ListItemSecondaryAction>
              </ListItem>
            </List>
            {/* <MaterialUISwitch
              sx={{ float: "right", mt: "1rem", zIndex: 10 }}
              onChange={() => {}}
              disabled={deviceState !== "IDLE"}
              checked={useBluetoothMode}
              onClick={() => setBluetoothModeEnabled(!bluetoothModeEnabled)}
            >
              <BluetoothIcon
                htmlColor="blue"
                sx={{ float: "right", zIndex: 11, color: "blue" }}
              />
            </MaterialUISwitch> */}
          </span>
          {getDisplayed()}
        </Paper>
      </ModalContainer>
    </>
  );
};

export const mapStateToProps = (state) => {
  const { selectedDevice, ...devices } = state.devices;
  if (!selectedDevice) return {};
  const { stateMachine: deviceState } = devices?.[selectedDevice];
  return {
    selectedDevice,
    deviceState,
  };
};

export default connect(mapStateToProps, {})(DeviceSetup);
