import React, { useRef, useEffect, useState } from 'react';
import * as d3 from 'd3';
import { da } from 'date-fns/locale';
import _ from 'lodash'

const HeatMap = ({ data, width, height, regions, hideNonRegion }) => {
    const d3Container = useRef(null);
    const [tooltip, setTooltip] = useState({ display: false, data: null, x: 0, y: 0 });

    useEffect(() => {
        if (data && d3Container.current) {
            let processedData = data.slice()
            if (regions?.length > 1 && hideNonRegion === true) {
                processedData = data.slice().filter(d => regions.includes(d.y) && (regions.includes(d.x)))
            }

            //const svg = d3.select(d3Container.current);
            d3.select(d3Container.current).selectAll('*').remove();

            const margin = { top: 30, right: 30, bottom: 200, left: 200 };
            const innerWidth = width - margin.left - margin.right;
            const innerHeight = height - margin.top - margin.bottom;

            // Append the SVG object to the body of the page
            const svg = d3.select(d3Container.current)
                .attr('width', width)
                .attr('height', height)
                .append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`);

            // Build X scales and axis
            const x = d3.scaleBand()
                .range([0, innerWidth])
                .domain(processedData.map(d => d.x))
                .padding(0.01);

            svg.append('g')
                .attr('transform', `translate(0, ${innerHeight})`)
                .call(d3.axisBottom(x)).selectAll('text')
                .style('text-anchor', 'end')
                .attr('dx', '-.8em')
                .attr('dy', '-.5em')
                .attr('transform', 'rotate(-90)')

            // Build Y scales and axis
            const y = d3.scaleBand()
                .range([innerHeight, 0])
                .domain(processedData.map(d => d.y).reverse())
                .padding(0.01)


            svg.append('g')
                .call(d3.axisLeft(y))

            svg.selectAll("g")
                .style("color", d => {
                    if (hideNonRegion === true) {
                        return 'black'
                    }
                    return regions.includes(d) ? "black" : "gray"
                })
                .style('font-style', 'italic')

            svg.selectAll('text')  // Select all text elements in the y-axis
                .each(function () {
                    let currentText = d3.select(this).text();
                    if (currentText === 'undefined') return '';
                    let capitalizedText = currentText.charAt(0).toUpperCase() + currentText.slice(1);
                    d3.select(this).text(capitalizedText);
                })


            const colorScale = d3.scaleLinear()
                .range(["white", "#69b3a2"])
                .domain([0, d3.max(processedData, d => d.value)]);


            svg.selectAll('rect')
                .data(processedData)
                .enter().append('rect')
                .attr('x', d => x(d.x))
                .attr('y', d => y(d.y))
                .style('stroke', '#DCDCDC')
                .attr('width', x.bandwidth())
                .attr('height', y.bandwidth())
                .style('fill', d => colorScale(d.value))
                .on('mouseover', (event, d) => {
                    const { x, y } = event?.target?.__data__ || {}
                    setTooltip({
                        display: true,
                        data: { x, y, value: d.value },
                        x: event.offsetX,
                        y: event.offsetY
                    });
                })
                .on('mouseout', function (d) {
                    setTooltip({
                        display: false,
                        data: `Value: ${d.value}`, // Replace with the data you want to show
                        x: 0,
                        y: 0
                    });
                });
        }
    }, [data, width, height, regions, hideNonRegion]);

    return (
        <div>
            <svg
                className="d3-component"
                width={width}
                height={height}
                ref={d3Container}
            />
            <div
                className="tooltip"
                style={{
                    position: 'absolute',
                    textAlign: 'center',
                    padding: '8px',
                    background: 'rgba(0, 0, 0, 0.6)',
                    borderRadius: '4px',
                    color: '#fff',
                    left: `${tooltip.x}px`,
                    top: `${tooltip.y}px`,
                    transform: 'translate(-50%, -100%)', // Adjust to position correctly above the cursor
                    pointerEvents: 'none',
                    zIndex: 1000, // High z-index to ensure it's above other elements
                    width: '250px',
                    display: tooltip?.display ? 'block' : 'none'
                }}
            >
                Truth:{tooltip?.data?.y || 0}<br />
                Pred:{tooltip?.data?.x || 0}<br />
                Value:{Math.round(tooltip?.data?.value * 100) / 100}
            </div>
            {tooltip.display && (
                <div
                    className="tooltip"
                    style={{
                        position: 'absolute',
                        textAlign: 'center',
                        padding: '8px',
                        background: 'rgba(0, 0, 0, 0.6)',
                        borderRadius: '4px',
                        color: '#fff',
                        left: `${tooltip.x}px`,
                        top: `${tooltip.y}px`,
                        transform: 'translate(-50%, -100%)', // Adjust to position correctly above the cursor
                        pointerEvents: 'none',
                        zIndex: 1000, // High z-index to ensure it's above other elements
                        width: '250px',
                    }}
                >
                    Truth:<em>{_.capitalize(tooltip.data.y)}</em><br />
                    Pred:<em>{_.capitalize(tooltip.data.x)}</em><br />
                    Value:{Math.round(tooltip.data.value * 100) / 100}
                </div>
            )}
        </div>
    );

}



export default HeatMap;