import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useWebServer } from "../../../../providers/WebServerProvider";
import { useHistory } from "react-router-dom";
import {
  getLicenseConfirmationStatus,
  confirmLicense,
  clearCapture,
} from "../../../../actions";
import TrayList from "./TrayList";
import licenseText from "./licenseText";
import CameraIcon from "@mui/icons-material/Camera";
import ROUTES from "../../../AppRouter/routes";
import ConfirmDialog from "../../../ConfirmDialog";
import { useAuth0 } from "../../../../providers/react-auth0-spa";
import { Box } from "@mui/material";
import ResponsiveButton from "../../../Forms/ResponsiveButton";

const Trays = ({
  selectedDevice,
  licenseConfirmed,
  getLicenseConfirmationStatus,
  confirmLicense,
  wsStatus,
  stateMachine,
  clearCapture,
}) => {
  const { user } = useAuth0();
  const { sendRequest } = useWebServer();
  const history = useHistory();
  const [disableCaptureButton, setDisableCaptureButton] = useState(true);
  const [pendingCaptureTray, setPendingCaptureTray] = useState(false);
  const [licenseDisplay, setLicenseDisplay] = useState(false);

  useEffect(() => {
    if (licenseConfirmed === "UNKNOWN") {
      const hasConfirmed =
        user?.["https://mos-id.com/user_metadata"]?.hasConfirmedLicense;
      getLicenseConfirmationStatus(hasConfirmed, sendRequest);
    }
  }, [licenseConfirmed, getLicenseConfirmationStatus, sendRequest, user]);

  useEffect(() => {
    if (licenseConfirmed === "CONFIRMED" && pendingCaptureTray) {
      setPendingCaptureTray(false);
      clearCapture();
      history.push(ROUTES.captureTray);
    }
  }, [licenseConfirmed, clearCapture, history, pendingCaptureTray]);

  useEffect(() => {
    const shouldDisable = !(
      wsStatus === "open" &&
      !!selectedDevice?.length &&
      stateMachine === "IDLE" &&
      (licenseConfirmed === "CONFIRMED" || licenseConfirmed === "NOT_CONFIRMED")
    );
    setDisableCaptureButton(shouldDisable);
  }, [selectedDevice, stateMachine, licenseConfirmed, wsStatus]);

  const onClickCaptureNewTray = () => {
    if (licenseConfirmed === "CONFIRMED") {
      clearCapture();
      history.push(ROUTES.captureTray);
    } else setLicenseDisplay(true);
  };

  const handleConfirmLicense = async () => {
    setPendingCaptureTray(true);
    confirmLicense(sendRequest);
  };

  return (
    <Box className={"flex flex-1 flex-col relative"} id={"trays"}
      sx={{
        display: ['none !important', 'flex !important']
      }}
    >
      <TrayList />
      <Box
        sx={{
          position: "absolute",
          bottom: "10px",
          left: "50%",
          transform: "translate(-50%, 0px)",
        }}
      >
        <ResponsiveButton
          variant={"contained"}
          color={"primary"}
          onClick={onClickCaptureNewTray}
          id={"capture-new-tray"}
          disabled={disableCaptureButton}
        >
          <CameraIcon className={"mr-1"} />
          Capture new Tray
        </ResponsiveButton>
      </Box>
      <ConfirmDialog
        id={"license-display-confirm-dialog"}
        title={"User Agreement"}
        open={licenseDisplay}
        setOpen={setLicenseDisplay}
        onConfirm={() => handleConfirmLicense()}
      >
        {licenseText}
      </ConfirmDialog>
    </Box>
  );
};

export const mapStateToProps = (state) => {
  const { confirmed: licenseConfirmed } = state.license;
  const { selectedDevice } = state.devices;
  if (!selectedDevice) return {};
  const { stateMachine } = state.devices[selectedDevice];
  const { status: wsStatus } = state.websocket;
  return {
    selectedDevice,
    stateMachine,
    licenseConfirmed,
    wsStatus,
  };
};

export default connect(mapStateToProps, {
  getLicenseConfirmationStatus,
  confirmLicense,
  clearCapture,
})(Trays);
