import React, { useEffect, useState, useCallback } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import DeviceSelector from "./DeviceSelector";
import { useWebServer } from "../../../providers";
import { DEFAULT_DATA_SETTINGS } from "../../../reducers/settingsReducer";
import {
  selectDevice,
  fetchTrays,
  initAllTrays,
  fetchOrganizationFilters,
  clearTrays
} from "./../../../actions";
import { phraseDownloadDataFilter } from "../../../utils/dataDownload";
import { useHistory } from "react-router-dom";

const defaultTraySettings = _.pick(DEFAULT_DATA_SETTINGS.Tray, [
  "page",
  "pageSize",
  "order",
  "orderBy",
]);

const Index = ({
  selectDevice,
  fetchTrays,
  initAllTrays,
  fetchOrganizationFilters,
  selectedDevice,
  devices,
  isLoading,
  disabled,
  queryParams,
  generalFilters,
  timeZone,
  clearTrays
}) => {
  const { sendRequest } = useWebServer();
  const [fetchedTrays, setFetchedTrays] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchOrganizationFilters(sendRequest);
  }, [fetchOrganizationFilters, sendRequest]);

  const fetchTraysHandler = _.debounce((generalFilters, timeZone, devices) => {
    let filters = phraseDownloadDataFilter(generalFilters, timeZone)
    //clear all tray on the tool
    Promise.all(
      Object.entries(devices).map(([deviceUuid, { id }]) =>
        clearTrays(deviceUuid)
      )
    );

    initAllTrays(Object.entries(devices), { ...defaultTraySettings, ...filters }, sendRequest, filters)
    setFetchedTrays(true);
  }, 1000, { leading: true, trailing: true })

  //eslint-disable-next-line
  const debounceFetchTrays = useCallback(fetchTraysHandler, []);

  useEffect(() => {
    if (history.location.pathname === "/data") { return }
    if (
      !fetchedTrays &&
      _.isObject(devices) &&
      Object.entries(devices).length > 0
    ) {
      debounceFetchTrays(generalFilters, timeZone, devices)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [devices, fetchedTrays, sendRequest]);

  useEffect(() => {
    setFetchedTrays(false)
  }, [generalFilters])


  const onChangeDevice = (obj) => {
    if (typeof obj?.target?.value === "string") {
      selectDevice(obj.target.value);
      const toolId = devices?.[obj.target.value]?.id;
      if (toolId) {
        fetchTrays(toolId, queryParams, sendRequest, obj.target.value);
      }
    }
  };
  return (
    <DeviceSelector
      devices={devices}
      selectedDevice={selectedDevice ? selectedDevice : "none"}
      handleChangeDevice={onChangeDevice}
      loading={isLoading}
      disabled={disabled}
    ></DeviceSelector>
  );
};

export const mapStateToProps = (state) => {
  const { selectedDevice, ...devices } = state.devices;
  const { capturing = false } = state.capture;
  const { confirmed: licenseConfirmed = "UNKNOWN" } = state.license;
  const { status: wifiSetupStatus } = state.wifi;
  const { status: wsStatus } = state.websocket;
  const { fetchingMacAddressStatus } = state.devices
  const queryParams = _.pick(state.settings.Tray, [
    "page",
    "pageSize",
    "order",
    "orderBy",
  ]);
  const disabled =
    wsStatus !== "open" ||
    capturing ||
    licenseConfirmed === "PENDING" ||
    !(wifiSetupStatus === "disconnected" || wifiSetupStatus === "ready")
    || !(fetchingMacAddressStatus === "disconnected" || fetchingMacAddressStatus === "ready")
    ;
  const isLoading =
    wsStatus !== "open" || !devices || Object.keys(devices).length === 0;

  const generalFilters = state.data?.generalFilters || {}
  const { timeZone } = state.user

  return {
    devices: _.pickBy(
      devices,
      (value, _) => value?.env === process.env.REACT_APP_ENV.toUpperCase() || value?.env === 'ALLTRAY'
    ),
    isLoading,
    selectedDevice,
    disabled,
    queryParams,
    generalFilters,
    timeZone
  };
};

export default connect(mapStateToProps, {
  selectDevice,
  initAllTrays,
  fetchTrays,
  fetchOrganizationFilters,
  clearTrays
})(Index);
