import React, { useState } from "react";
import { connect } from "react-redux";
import UserMenu from "./UserMenu";

const Index = ({ superUser, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setAnchorEl(null);
    }
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <UserMenu
      superUser={superUser}
      otherProps={props}
      handleClick={handleClick}
      handleClose={handleClose}
      handleListKeyDown={handleListKeyDown}
      anchorEl={anchorEl}
    ></UserMenu>
  );
};

export const mapStateToProps = (state) => {
  const { superUser } = state.user;
  return {
    superUser,
  };
};

export default connect(mapStateToProps, {})(Index);
