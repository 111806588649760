import React, { useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import _ from "lodash";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import AutoCompleteField from "../../../Forms/AutoCompleteField";
import ConfirmDialog from "../../../ConfirmDialog";
import {
  Typography,
  TextField,
  CircularProgress,
  Chip,
  Grid,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Switch,
  Divider
} from "@mui/material";
import { phraseDownloadDataFilter } from "../../../../utils/dataDownload";
import DownloadMenu from "./DownloadMenu";
// eslint-disable-next-line no-unused-vars
import { sexOptions, algorithmSexOptions } from "../../../../utils/const";
import ResponsiveButton from "../../../Forms/ResponsiveButton";

const styleForDateTimePicker = {
  width: "100%",
  "& .MuiInputLabel-root": {
    fontSize: ["1rem", "1rem", ".7rem", ".7rem", "1rem", "1rem"],
    transform: {
      md: "matrix(1, 0, 0, 1, 0, 15)",
      lg: "matrix(1, 0, 0, 1, 0, 20)",
    },
  },
  "& .MuiInputLabel-shrink": {
    transform: {
      md: "matrix(0.75, 0, 0, 0.75, 0, -1.5)",
    },
  },
  "& .MuiInput-root": {
    mt: ["1rem", ".5rem", ".5rem", ".5rem", "1rem", "1rem"],
  },
  "& .MuiInput-input": {
    fontSize: ["1rem", ".66rem", ".66rem", ".66rem", "1rem", "1rem"],
  },
  "& .MuiInputAdornment-positionEnd": {
    mr: ["1rem", ".5rem", ".5rem", ".5rem", "1rem", "1rem"],
  },
  "& .MuiSvgIcon-root": {
    fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
  },
};

const checkboxSx = {
  "& .MuiTypography-root": {
    fontSize: ["1rem", ".66rem", ".66rem", ".66rem", "1rem", "1rem"],
  },
  "& .MuiSvgIcon-root": {
    fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
  },
}

const buttonStyles = {
  button: {
    background: "status.info",
    color: "white.main",
  },
};

const Data = ({
  handleDownloadData,
  disableDownloadButton,
  showLoading,
  allDownloadConfirm,
  setAllDownloadConfirm,
  timeZone,
  generalFilters,
  updateGeneralFilters,
  clearGeneralFilters,
  options,
}) => {
  const {
    algorithmGenusSpeciesOptions,
    contributedGenusSpeciesOptions,
    contributedPlatesOptions,
    trapTypesOptions,
    deviceOptions,
    trapSiteOptions,
    usersOptions,
    tagsOptions
  } = options;
  const {
    deviceID,
    imagingStartDate,
    imagingEndDate,
    specimenCaptureStartDate,
    specimenCaptureEndDate,
    genusSpecies,
    plates,
    trapSitesFilter,
    trapTypesFilter,
    algorithmGenusSpeciesFilter,
    sexFilter,
    // eslint-disable-next-line no-unused-vars
    algorithmSexFilter,
    usersFilter,
    specimenTagFilter,
    excludeEmpty,
    excludeUnknown,
    excludeAlgorithmUnsure
  } = generalFilters;
  const setDeviceID = (val) => updateGeneralFilters({ deviceID: val });
  const setImagingStartDate = (val) =>
    updateGeneralFilters({ imagingStartDate: val });
  const setImagingEndDate = (val) =>
    updateGeneralFilters({ imagingEndDate: val });
  const setSpecimenCaptureStartDate = (val) =>
    updateGeneralFilters({ specimenCaptureStartDate: val });
  const setSpecimenCaptureEndDate = (val) =>
    updateGeneralFilters({ specimenCaptureEndDate: val });
  const setGenusSpecies = (val) => updateGeneralFilters({ genusSpecies: val });
  const setPlates = (val) => updateGeneralFilters({ plates: val });
  const setTrapSitesFilter = (val) =>
    updateGeneralFilters({ trapSitesFilter: val });
  const setTrapTypesFilter = (val) =>
    updateGeneralFilters({ trapTypesFilter: val });
  const setAlgorithmGenusSpeciesFilter = (val) =>
    updateGeneralFilters({ algorithmGenusSpeciesFilter: val });
  const setUsersFilter = (val) => updateGeneralFilters({ usersFilter: val });
  const setSexFilter = (val) => updateGeneralFilters({ sexFilter: val });
  // eslint-disable-next-line no-unused-vars
  const setAlgorithmSexFilter = (val) =>
    updateGeneralFilters({ algorithmSexFilter: val });
  const setSpecimenTagFilter = (val) =>
    updateGeneralFilters({ specimenTagFilter: val });
  const setExcludeEmpty = () =>
    updateGeneralFilters({ excludeEmpty: !excludeEmpty });
  const setExcludeUnknown = () =>
    updateGeneralFilters({ excludeUnknown: !excludeUnknown });
  const setExcludeAlgorithmUnsure = () =>
    updateGeneralFilters({ excludeAlgorithmUnsure: !excludeAlgorithmUnsure });

  const [hideAdvancedFilters, setHideAdvancedFilters] = React.useState(true);

  const hasNoFilters =
    imagingStartDate === null &&
    imagingEndDate === null &&
    specimenCaptureStartDate === null &&
    specimenCaptureEndDate === null &&
    deviceID.length === 0 &&
    genusSpecies.length === 0 &&
    plates.length === 0 &&
    trapSitesFilter.length === 0 &&
    trapTypesFilter.length === 0 &&
    algorithmGenusSpeciesFilter.length === 0 &&
    usersFilter.length === 0 &&
    sexFilter.length === 0 &&
    specimenTagFilter.length === 0;

  const computeHelperText = (props, error, startDate, endDate) => {
    if (error) {
      if (startDate > endDate) {
        if (props?.label === "Start Datetime")
          return "Date should not be after maximal date";
        else return "Date should not be before minimal date";
      }
      if (props?.inputProps?.value?.length === "mm/dd/yyyy hh:mm".length) {
        return "Invalid Date Format";
      } else {
        return "mm/dd/yyyy hh:mm";
      }
    }
    return "";
  };

  useEffect(() => {
    return () => {
      clearGeneralFilters();
    };
  }, [clearGeneralFilters]);

  const onDownloadData = async (DownloadDataType) => {
    let filters = phraseDownloadDataFilter(generalFilters, timeZone);
    await handleDownloadData({ filters }, DownloadDataType);
  };

  return (
    <Box className={"flex flex-col relative max-w-full"}
      sx={{
        display: ['none !important', 'flex !important']
      }}
    >
      <Typography
        sx={{
          fontSize: [
            ".75rem",
            ".75rem",
            ".75rem",
            ".75rem",
            "1.15rem",
            "1.25rem",
          ],
        }}
        className={"text-gray-600 mt-3"}
      >
        Data Download Filters
      </Typography>
      <div className={" flex flex-col"}>
        <Typography
          sx={{
            fontSize: [".75rem", ".75rem", ".75rem", ".75rem", "1rem", "1rem"],
          }}
          className={"text-gray-800 mt-3"}
        >
          Imaging Date:
        </Typography>
        <Grid container spacing={3}>
          <Grid item md={6} lg={6}>
            <DateTimePicker
              autoOk
              style={{ maxWidth: "4rem" }}
              ampm={true}
              value={imagingStartDate}
              onChange={setImagingStartDate}
              inputFormat="MM/dd/yyyy HH:mm"
              label="Start Datetime"
              mask="__/__/____ __:__"
              maxDate={imagingEndDate}
              showToolbar
              renderInput={(props) => (
                <TextField
                  data-testid="Imaging Start Datetime"
                  label="Start Datetime"
                  variant="standard"
                  sx={styleForDateTimePicker}
                  {...props}
                  helperText={computeHelperText(
                    props,
                    props.error,
                    imagingStartDate,
                    imagingEndDate
                  )}
                // style={{ paddingRight: 10 }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} lg={6}>
            <DateTimePicker
              style={{ maxWidth: "10rem" }}
              label="End Datetime"
              value={imagingEndDate}
              onChange={setImagingEndDate}
              inputFormat="MM/dd/yyyy HH:mm"
              mask="__/__/____ __:__"
              minDate={imagingStartDate}
              showToolbar={true}
              renderInput={(props) => (
                <TextField
                  data-testid="Imaging End Datetime"
                  label="End Datetime"
                  variant="standard"
                  sx={styleForDateTimePicker}
                  {...props}
                  helperText={computeHelperText(
                    props,
                    props.error,
                    imagingStartDate,
                    imagingEndDate
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
        <Grid>
          <Typography
            sx={{
              fontSize: [
                ".75rem",
                ".75rem",
                ".75rem",
                ".75rem",
                "1rem",
                "1rem",
              ],
            }}
            className={"text-gray-800 mt-3"}
          >
            Specimen Capture Date:
          </Typography>
        </Grid>
        <Grid container spacing={3}>
          <Grid item md={6} lg={6}>
            <DateTimePicker
              autoOk
              style={{ maxWidth: "4rem" }}
              ampm={true}
              value={specimenCaptureStartDate}
              onChange={setSpecimenCaptureStartDate}
              inputFormat="MM/dd/yyyy HH:mm"
              label="Start Datetime"
              mask="__/__/____ __:__"
              maxDate={specimenCaptureEndDate}
              showToolbar
              renderInput={(props) => (
                <TextField
                  data-testid="Specimen Capture Start Datetime"
                  label="Start Datetime"
                  variant="standard"
                  sx={styleForDateTimePicker}
                  {...props}
                  helperText={computeHelperText(
                    props,
                    props.error,
                    specimenCaptureStartDate,
                    specimenCaptureEndDate
                  )}
                // style={{ paddingRight: 10 }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} lg={6}>
            <DateTimePicker
              style={{ maxWidth: "10rem" }}
              label="End Datetime"
              value={specimenCaptureEndDate}
              onChange={setSpecimenCaptureEndDate}
              inputFormat="MM/dd/yyyy HH:mm"
              mask="__/__/____ __:__"
              minDate={specimenCaptureStartDate}
              showToolbar={true}
              renderInput={(props) => (
                <TextField
                  data-testid="Specimen Capture End Datetime"
                  label="End Datetime"
                  variant="standard"
                  sx={styleForDateTimePicker}
                  {...props}
                  helperText={computeHelperText(
                    props,
                    props.error,
                    specimenCaptureStartDate,
                    specimenCaptureEndDate
                  )}
                />
              )}
            />
          </Grid>
        </Grid>
        <Box sx={{
          mt: '1rem',
        }}>
          <Typography
            sx={{
              float: 'left',
              fontSize: [
                ".75rem",
                ".75rem",
                ".75rem",
                ".75rem",
                "1rem",
                "1rem",
              ],
            }}
            className={"text-gray-800 mt-3"}
          >Advance Filters</Typography>
          <Switch sx={{ float: 'right', mt: '.25rem' }}
            onChange={() => { }}
            checked={!hideAdvancedFilters}
            onClick={() => setHideAdvancedFilters(!hideAdvancedFilters)}
          />
        </Box>
        <Divider sx={{ mt: ".5rem" }} />
        {!hideAdvancedFilters && <>
          <AutoCompleteField
            label={"Device Filter"}
            disableCloseOnSelect
            value={deviceID}
            onChange={(e, newValue) => {
              setDeviceID(newValue);
            }}
            getOptionLabel={(option) => option.displayName}
            multiple
            options={deviceOptions}
            isOptionEqualToValue={(option, value) => {
              return option.id === value.id;
            }}
          />
          <AutoCompleteField
            label={"Contributed Species Filter"}
            value={genusSpecies}
            disableCloseOnSelect
            onChange={(e, newValue) => {
              setGenusSpecies(newValue);
            }}
            getOptionLabel={(option) =>
              `${option.name?.genus} ${option.name?.species}`
            }
            multiple
            options={contributedGenusSpeciesOptions}
          />
          <AutoCompleteField
            label={"Algorithm Species Filter"}
            value={algorithmGenusSpeciesFilter}
            disableCloseOnSelect
            onChange={(e, newValue) => {
              setAlgorithmGenusSpeciesFilter(newValue);
            }}
            getOptionLabel={(option) =>
              `${option.name.genus} ${option.name.species}`
            }
            multiple
            options={algorithmGenusSpeciesOptions}
          />
          <AutoCompleteField
            label={"Plates Filter"}
            value={plates}
            disableCloseOnSelect
            onChange={(e, newValue) => {
              setPlates(newValue);
            }}
            getOptionLabel={(option) => option}
            multiple
            options={contributedPlatesOptions}
          />
          <AutoCompleteField
            label={"Contributed Sex Filter"}
            disableCloseOnSelect
            value={sexFilter}
            onChange={(e, newValue) => {
              setSexFilter(newValue);
            }}
            getOptionLabel={(option) => option}
            multiple
            options={sexOptions}
          />
          {/* <AutoCompleteField
          label={"Algorithm Sex Filter"}
          disableCloseOnSelect
          value={algorithmSexFilter}
          onChange={(e, newValue) => {
            setAlgorithmSexFilter(newValue);
          }}
          getOptionLabel={(option) => option}
          multiple
          options={algorithmSexOptions}
        /> */}
          <AutoCompleteField
            label={"Collection Sites Filter"}
            disableCloseOnSelect
            value={trapSitesFilter}
            onChange={(e, newValue) => {
              setTrapSitesFilter(newValue);
            }}
            getOptionLabel={(option) => option.displayName}
            multiple
            options={trapSiteOptions}
          />
          <AutoCompleteField
            label={"Collection Methods Filter"}
            value={trapTypesFilter}
            disableCloseOnSelect
            onChange={(e, newValue) => {
              setTrapTypesFilter(newValue);
            }}
            getOptionLabel={(option) => option}
            multiple
            options={trapTypesOptions}
          />
          <AutoCompleteField
            label={"Users Filter"}
            value={usersFilter}
            disableCloseOnSelect
            onChange={(e, newValue) => {
              setUsersFilter(newValue);
            }}
            getOptionLabel={(option) => option}
            multiple
            options={usersOptions}
          />
          <AutoCompleteField
            label={"Specimens and Tray Tag Filter"}
            value={specimenTagFilter}
            disableCloseOnSelect
            onChange={(e, newValue) => {
              setSpecimenTagFilter(newValue);
            }}
            getOptionLabel={(option) => option}
            multiple
            freeSolo
            options={tagsOptions}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="filled"
                label="freeSolo"
                placeholder="Favorites"
              />
            )}
          />
        </>}

        <FormControl component="fieldset" sx={{ mt: '1rem' }}>
          <FormLabel component="legend">Exclude</FormLabel>
          <FormGroup aria-label="position" row>
            <FormControlLabel
              checked={excludeEmpty}
              value="empty"
              control={<Checkbox />}
              label="empty"
              sx={checkboxSx}
              onClick={() => setExcludeEmpty()}
            />
            <FormControlLabel
              checked={excludeUnknown}
              value="unknown"
              control={<Checkbox />}
              label="unknown"
              sx={checkboxSx}
              onClick={() => setExcludeUnknown()}
            />
            <FormControlLabel
              checked={excludeAlgorithmUnsure}
              value="Algorithm Unsure"
              control={<Checkbox />}
              label="Algorithm Unsure"
              sx={checkboxSx}
              onClick={() => setExcludeAlgorithmUnsure()}
            />
          </FormGroup>
        </FormControl>

        <Box
          className={"flex"}
          sx={{
            mt: ["1.5rem", "1.5rem", ".75rem", "1.5rem", "1.5rem"],
            justifyContent: "space-around",
          }}
        >
          {showLoading ? (
            <CircularProgress className={"ml-2"} size={"2rem"} />
          ) : (
            <>
              <ResponsiveButton
                onClick={clearGeneralFilters}
                sx={buttonStyles}
                id={"reset-data-download-filters"}
              >
                Reset
              </ResponsiveButton>
              <DownloadMenu
                hasNoFilters={hasNoFilters}
                setAllDownloadConfirm={setAllDownloadConfirm}
                onDownloadData={onDownloadData}
                disableDownloadButton={disableDownloadButton}
              />
            </>
          )}
        </Box>
      </div >
      <ConfirmDialog
        title={`Are you sure you want to download all data from your organization? This may take a long time.`}
        open={allDownloadConfirm}
        setOpen={setAllDownloadConfirm}
        onConfirm={onDownloadData}
      >
        {
          "If not, hit cancel and use some filters. Try limiting the query by date captured, device, or trap site."
        }
      </ConfirmDialog>
    </Box >
  );
};

export default Data;
