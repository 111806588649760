import React from "react";
import FormControl from "@mui/material/FormControl";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Fab from "@mui/material/Fab";
import CircularProgress from "@mui/material/CircularProgress";
import CancelIcon from "@mui/icons-material/Cancel";
import SignalCellular4BarTwoToneIcon from "@mui/icons-material/SignalCellular4BarTwoTone";
import { Box, Dialog, FilledInput, InputLabel, InputAdornment, IconButton } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const fabSx = {
    fontSize: ["0.55rem", "0.55rem", "0.6rem", "0.6rem", "0.875rem", "0.875rem"],
    "& .MuiSvgIcon-root": {
        fontSize: [
            "0.55rem",
            "0.55rem",
            "0.83rem !important",
            "0.83rem !important",
            "1.25rem !important",
            "1.25rem !important",
        ],
    },
};

const textAreaSx = {
    m: ".5rem",
    minWidth: '300px',
    "& .MuiInputLabel-root": {
        fontSize: [".5rem", ".5rem", ".8rem", ".8rem", "1.15rem", "1.15rem"],
        transform: {
            md: "translate(12px, 10px) scale(1)",
            lg: "translate(12px, 16px) scale(1)",
        },
    },
    "& .MuiInputLabel-shrink": {
        transform: {
            md: "translate(12px, 3px) scale(0.75)",
            lg: "translate(12px, 7px) scale(0.75)",
        },
    },
    "& .MuiInputBase-input": {
        paddingTop: "0rem",
        paddingBottom: "0rem",
    },
    "& .MuiFilledInput-root": {
        paddingTop: ["1.0rem", "1.0rem", "1.0rem", "1.0rem", "25px", "25px"],
        paddingBottom: ["5px", "5px", "5px", "5px", "8px", "8px"],
        fontSize: [".5rem", ".5rem", ".75rem", ".75rem", "1rem", "1rem"],
        lineHeight: ["1rem", "1rem", "1rem", "1rem", "1.4375rem", "1.4375rem"],
    },
};

const WifiModal = ({
    setupState,
    handleChangePSK,
    handleConnectButtonPressed,
    handleCancelButtonPressed,
    wifiList,
    ssid,
    psk,
    setClose,
    open,
}) => {
    const connecting = setupState === "connecting";
    const loading = false;
    const disabled = loading || ssid.length === 0;

    const [showPassword, setShowPassword] = React.useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);
    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Dialog
            onClose={setClose}
            open={open}
        >
            <Paper
                sx={{
                    height: ["400px", "400px", "400px", "400px", "400px", "400px"],
                    width: ["100vw", "400px", "400px", "400px", "400px", "400px"],
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
                className={
                    "bg-white rounded-xl border-4 border-transparent m-auto transition-opacity duration-1000"
                }
            >
                <Box
                    className={"flex flex-1 flex-col items-center justify-center"}
                    id={"wifi-credentials"}
                >
                    <span className={"text-gray-600 font-medium px-2"}>
                        Enter WiFi Credentials
                    </span>

                    <TextField
                        id="ssid-field"
                        labelid="ssid-field-label"
                        label="WiFi Name"
                        variant="standard"
                        value={ssid}
                        InputProps={{
                            readOnly: true,
                        }}
                        sx={{ minWidth: '300px' }}
                    />

                    <FormControl sx={textAreaSx} variant="filled">
                        <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
                        <FilledInput
                            id="psk-field"
                            labelid="psk-field-label"
                            label="WiFi PSK"
                            variant="filled"
                            type={showPassword ? 'text' : 'password'}
                            value={psk}
                            onChange={handleChangePSK}
                            disabled={loading}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>

                    <div
                        className={"flex flex-row items-center justify-center"}
                    >
                        <Fab
                            id={"wifi-connect-button"}
                            style={{ marginRight: "0.5rem" }}
                            variant={"extended"}
                            color={"primary"}
                            size={"small"}
                            disabled={disabled}
                            onClick={handleConnectButtonPressed}
                            sx={fabSx}
                        >
                            {connecting ? (
                                <CircularProgress className={"p-2"} />
                            ) : (
                                <SignalCellular4BarTwoToneIcon className={"mr-1"} />
                            )}
                            Connect
                        </Fab>
                        {handleCancelButtonPressed &&
                            <Fab
                                id={"wifi-cancel-button"}
                                style={{ marginLeft: "0.5rem" }}
                                variant={"extended"}
                                color={"secondary"}
                                size={"small"}
                                disabled={!connecting}
                                onClick={handleCancelButtonPressed}
                                sx={fabSx}
                            >
                                {<CancelIcon className={"mr-1"} />}
                                Cancel
                            </Fab>
                        }
                    </div>
                </Box>
            </Paper >
        </Dialog>
    );
};

export default WifiModal;
