import React from "react";
import { Typography, Box, Grid, TextField, Chip } from "@mui/material";
import AutoCompleteField from "../../../Forms/AutoCompleteField";
import { DateTimePicker } from "@mui/x-date-pickers";
import { sexOptions } from "../../../../utils/const";
import ResponsiveButton from "../../../Forms/ResponsiveButton";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

const fontSx = {
  fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
};

const buttonStyles = {
  button: {
    background: "status.info",
    color: "white.main",
  },
};

const styleForDateTimePicker = {
  width: "100%",
  "& .MuiInputLabel-root": {
    fontSize: ["1rem", "1rem", ".7rem", ".7rem", "1rem", "1rem"],
    transform: {
      md: "matrix(1, 0, 0, 1, 0, 15)",
      lg: "matrix(1, 0, 0, 1, 0, 20)",
    },
  },
  "& .MuiInputLabel-shrink": {
    transform: {
      md: "matrix(0.75, 0, 0, 0.75, 0, -1.5)",
    },
  },
  "& .MuiInput-root": {
    mt: ["1rem", ".5rem", ".5rem", ".5rem", "1rem", "1rem"],
  },
  "& .MuiInput-input": {
    fontSize: ["1rem", ".66rem", ".66rem", ".66rem", "1rem", "1rem"],
  },
  "& .MuiInputAdornment-positionEnd": {
    mr: ["1rem", ".5rem", ".5rem", ".5rem", "1rem", "1rem"],
  },
  "& .MuiSvgIcon-root": {
    fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
  },
};

const computeHelperText = (props, error, startDate, endDate) => {
  if (error) {
    if (startDate > endDate) {
      if (props?.label === "Start Datetime")
        return "Date should not be after maximal date";
      else return "Date should not be before minimal date";
    }
    if (props?.inputProps?.value?.length === "mm/dd/yyyy hh:mm".length) {
      return "Invalid Date Format";
    } else {
      return "mm/dd/yyyy hh:mm";
    }
  }
  return "";
};

const TrayFilterModal = ({
  generalFilters,
  updateGeneralFilters,
  options,
  clearGeneralFilters,
  handleClose,
}) => {
  const {
    algorithmGenusSpeciesOptions,
    contributedGenusSpeciesOptions,
    contributedPlatesOptions,
    trapTypesOptions,
    // deviceOptions,
    trapSiteOptions,
    usersOptions,
    tagsOption,
  } = options;
  const {
    // eslint-disable-next-line no-unused-vars
    deviceID,
    imagingStartDate,
    imagingEndDate,
    specimenCaptureStartDate,
    specimenCaptureEndDate,
    genusSpecies,
    plates,
    trapSitesFilter,
    trapTypesFilter,
    algorithmGenusSpeciesFilter,
    sexFilter,
    // eslint-disable-next-line no-unused-vars
    algorithmSexFilter,
    usersFilter,
    specimenTagFilter,
  } = generalFilters;
  // const setDeviceID = (val) => updateGeneralFilters({ 'deviceID': val })
  const setImagingStartDate = (val) =>
    updateGeneralFilters({ imagingStartDate: val });
  const setImagingEndDate = (val) =>
    updateGeneralFilters({ imagingEndDate: val });
  const setSpecimenCaptureStartDate = (val) =>
    updateGeneralFilters({ specimenCaptureStartDate: val });
  const setSpecimenCaptureEndDate = (val) =>
    updateGeneralFilters({ specimenCaptureEndDate: val });
  const setGenusSpecies = (val) => updateGeneralFilters({ genusSpecies: val });
  const setPlates = (val) => updateGeneralFilters({ plates: val });
  const setTrapSitesFilter = (val) =>
    updateGeneralFilters({ trapSitesFilter: val });
  const setTrapTypesFilter = (val) =>
    updateGeneralFilters({ trapTypesFilter: val });
  const setAlgorithmGenusSpeciesFilter = (val) =>
    updateGeneralFilters({ algorithmGenusSpeciesFilter: val });
  const setUsersFilter = (val) => updateGeneralFilters({ usersFilter: val });
  const setSexFilter = (val) => updateGeneralFilters({ sexFilter: val });
  const setSpecimenTagFilter = (val) =>
    updateGeneralFilters({ specimenTagFilter: val });

  return (
    <Box sx={{ overflowY: "auto" }}>
      <Grid sx={{ m: 2 }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={1} />
          <Grid item xs={10}>
            <Typography sx={fontSx} className={" text-gray-600 text-center"}>
              {" "}
              Tray Filter{" "}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <Tooltip
              title={`Close`}
              sx={{
                float: "right",
              }}
            >
              <IconButton
                sx={{
                  color: "status.info",
                  padding: [
                    "0.35rem",
                    "0.35rem",
                    "0.35rem",
                    "0.5rem",
                    "0.5rem",
                    "0.5rem",
                  ],
                }}
                onClick={() => handleClose()}
              >
                <CloseIcon
                  sx={{
                    fontSize: [
                      "1rem",
                      "1rem",
                      ".90rem",
                      "1rem",
                      "1.75rem",
                      "1.75rem",
                    ],
                  }}
                />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: [
                  ".75rem",
                  ".75rem",
                  ".75rem",
                  ".75rem",
                  "1rem",
                  "1rem",
                ],
              }}
              className={"text-gray-800 mt-3"}
            >
              Imaging Date:
            </Typography>
          </Grid>
          <Grid item md={6} lg={6}>
            <DateTimePicker
              autoOk
              style={{ maxWidth: "4rem" }}
              ampm={true}
              value={imagingStartDate}
              onChange={setImagingStartDate}
              inputFormat="MM/dd/yyyy HH:mm"
              label="Start Datetime"
              mask="__/__/____ __:__"
              maxDate={imagingEndDate}
              showToolbar
              renderInput={(props) => (
                <TextField
                  data-testid="Imaging Start Datetime"
                  label="Start Datetime"
                  variant="standard"
                  sx={styleForDateTimePicker}
                  {...props}
                  helperText={computeHelperText(
                    props,
                    props.error,
                    imagingStartDate,
                    imagingEndDate
                  )}
                />
              )}
            />
          </Grid>
          <Grid item md={6} lg={6}>
            <DateTimePicker
              style={{ maxWidth: "10rem" }}
              label="End Datetime"
              value={imagingEndDate}
              onChange={setImagingEndDate}
              inputFormat="MM/dd/yyyy HH:mm"
              mask="__/__/____ __:__"
              minDate={imagingStartDate}
              showToolbar={true}
              renderInput={(props) => (
                <TextField
                  data-testid="Imaging End Datetime"
                  label="End Datetime"
                  variant="standard"
                  sx={styleForDateTimePicker}
                  {...props}
                  helperText={computeHelperText(
                    props,
                    props.error,
                    imagingStartDate,
                    imagingEndDate
                  )}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography
              sx={{
                fontSize: [
                  ".75rem",
                  ".75rem",
                  ".75rem",
                  ".75rem",
                  "1rem",
                  "1rem",
                ],
                display: "bolck",
              }}
              className={"text-gray-800 mt-3"}
            >
              Specimen Capture Date:
            </Typography>
          </Grid>
          <Grid item md={6} lg={6}>
            <DateTimePicker
              autoOk
              style={{ maxWidth: "4rem" }}
              ampm={true}
              value={specimenCaptureStartDate}
              onChange={setSpecimenCaptureStartDate}
              inputFormat="MM/dd/yyyy HH:mm"
              label="Start Datetime"
              mask="__/__/____ __:__"
              maxDate={specimenCaptureEndDate}
              showToolbar
              renderInput={(props) => (
                <TextField
                  data-testid="Specimen Capture Start Datetime"
                  label="Start Datetime"
                  variant="standard"
                  sx={styleForDateTimePicker}
                  {...props}
                  helperText={computeHelperText(
                    props,
                    props.error,
                    specimenCaptureStartDate,
                    specimenCaptureEndDate
                  )}
                  // style={{ paddingRight: 10 }}
                />
              )}
            />
          </Grid>
          <Grid item md={6} lg={6}>
            <DateTimePicker
              style={{ maxWidth: "10rem" }}
              label="End Datetime"
              value={specimenCaptureEndDate}
              onChange={setSpecimenCaptureEndDate}
              inputFormat="MM/dd/yyyy HH:mm"
              mask="__/__/____ __:__"
              minDate={specimenCaptureStartDate}
              showToolbar={true}
              renderInput={(props) => (
                <TextField
                  data-testid="Specimen Capture End Datetime"
                  label="End Datetime"
                  variant="standard"
                  sx={styleForDateTimePicker}
                  {...props}
                  helperText={computeHelperText(
                    props,
                    props.error,
                    specimenCaptureStartDate,
                    specimenCaptureEndDate
                  )}
                />
              )}
            />
          </Grid>
          <Grid item sm={12} lg={12}>
            <AutoCompleteField
              label={"Contributed Species Filter"}
              value={genusSpecies}
              disableCloseOnSelect
              onChange={(e, newValue) => {
                setGenusSpecies(newValue);
              }}
              getOptionLabel={(option) =>
                `${option.name?.genus} ${option.name?.species}`
              }
              multiple
              options={contributedGenusSpeciesOptions}
            />
          </Grid>
          <Grid item sm={12} lg={12}>
            <AutoCompleteField
              label={"Algorithm Species Filter"}
              value={algorithmGenusSpeciesFilter}
              disableCloseOnSelect
              onChange={(e, newValue) => {
                setAlgorithmGenusSpeciesFilter(newValue);
              }}
              getOptionLabel={(option) =>
                `${option.name.genus} ${option.name.species}`
              }
              multiple
              options={algorithmGenusSpeciesOptions}
            />
          </Grid>
          <Grid item sm={12} lg={12}>
            <AutoCompleteField
              label={"Plates Filter"}
              value={plates}
              disableCloseOnSelect
              onChange={(e, newValue) => {
                setPlates(newValue);
              }}
              getOptionLabel={(option) => option}
              multiple
              options={contributedPlatesOptions}
            />
          </Grid>
          <Grid item sm={12} lg={12}>
            <AutoCompleteField
              label={"Contributed Sex Filter"}
              disableCloseOnSelect
              value={sexFilter}
              onChange={(e, newValue) => {
                setSexFilter(newValue);
              }}
              getOptionLabel={(option) => option}
              multiple
              options={sexOptions}
            />
          </Grid>
          {/* <AutoCompleteField
          label={"Algorithm Sex Filter"}
          disableCloseOnSelect
          value={algorithmSexFilter}
          onChange={(e, newValue) => {
            setAlgorithmSexFilter(newValue);
          }}
          getOptionLabel={(option) => option}
          multiple
          options={algorithmSexOptions}
        /> */}
          <Grid item sm={12} lg={12}>
            <AutoCompleteField
              label={"Collection Sites Filter"}
              disableCloseOnSelect
              value={trapSitesFilter}
              onChange={(e, newValue) => {
                setTrapSitesFilter(newValue);
              }}
              getOptionLabel={(option) => option.displayName}
              multiple
              options={trapSiteOptions}
            />
          </Grid>
          <Grid item sm={12} lg={12}>
            <AutoCompleteField
              label={"Collection Methods Filter"}
              value={trapTypesFilter}
              disableCloseOnSelect
              onChange={(e, newValue) => {
                setTrapTypesFilter(newValue);
              }}
              getOptionLabel={(option) => option}
              multiple
              options={trapTypesOptions}
            />
          </Grid>
          <Grid item sm={12} lg={12}>
            <AutoCompleteField
              label={"Users Filter"}
              value={usersFilter}
              disableCloseOnSelect
              onChange={(e, newValue) => {
                setUsersFilter(newValue);
              }}
              getOptionLabel={(option) => option}
              multiple
              options={usersOptions}
            />
          </Grid>
          <Grid item sm={12} lg={12}>
            <AutoCompleteField
              label={"Specimens and Tray Tag Filter"}
              value={specimenTagFilter}
              disableCloseOnSelect
              onChange={(e, newValue) => {
                setSpecimenTagFilter(newValue);
              }}
              getOptionLabel={(option) => option}
              multiple
              freeSolo
              options={tagsOption}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    label={option}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="filled"
                  label="freeSolo"
                  placeholder="Favorites"
                />
              )}
            />
          </Grid>
          <Grid item sm={12} lg={12}>
            <Box
              className={"flex"}
              sx={{
                mt: ["1.5rem", "1.5rem", ".75rem", "1.5rem", "1.5rem"],
                justifyContent: "space-around",
              }}
            >
              <ResponsiveButton
                onClick={(e) => {
                  e.stopPropagation();
                  clearGeneralFilters();
                }}
                sx={buttonStyles}
                id={"reset-data-download-filters"}
              >
                Reset
              </ResponsiveButton>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TrayFilterModal;
