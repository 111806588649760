import React from "react";
import MenuItem from "@mui/material/MenuItem";
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { Typography } from "@mui/material";

const LogoutButton = (props) => {
    const { onClick } = props;
    return (
        <MenuItem color="primary" onClick={onClick}>
            <RestartAltIcon />
            <Typography sx={{ pl: 1 }}>Reboot</Typography>
        </MenuItem>
    );
};

export default LogoutButton;
