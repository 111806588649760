import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import DataSummaryGraph from "./DataSummaryGraph";
import { MenuList, Paper, MenuItem, Typography, Box } from "@mui/material";
import AutoGraphIcon from '@mui/icons-material/AutoGraph';
import CloseIcon from '@mui/icons-material/Close';
import DataSummaryGraphTableLegend from './DataSummaryGraphTableLegend'
import { phraseDownloadDataFilter } from "../../../../utils/dataDownload";
import { useWebServer } from "../../../../providers";
import _ from 'lodash'

const Index = ({ generalFilters, timeZone }) => {
    const [expand, setExpand] = useState(false)
    const [legendData, setLegendData] = useState({ dataInner: null, dataOuter: null })
    const [graphPointerEvent, setGraphPointerEvent] = useState(true)
    const { getOrganizationSummaryData } = useWebServer();
    const [noMatchData, setNoMatchData] = useState(true)
    const [dataInner, setDataInner] = useState([])
    const [dataOuter, setDataOuter] = useState([])
    const [activeIndex, setActiveIndex] = useState(undefined)
    const [data, setData] = useState([])

    const fetchDataHandler = _.debounce((generalFilters, timeZone) => {
        const filters = phraseDownloadDataFilter(generalFilters, timeZone)
        getOrganizationSummaryData({ filters }).then((data) => {
            for (let i of JSON.parse(data)) {
                if (i['count'] === "no match data") {
                    setNoMatchData(true)
                }
                else { setNoMatchData(false) }
            }
            let array_data = _.sortBy(JSON.parse(data), ['genus', 'species'])
            array_data = array_data.map((cur, index) => { return { ...cur, "genus": _.capitalize(cur.genus), visible: true, fullSpecies: _.capitalize(cur.genus) + ' ' + cur.species } })
            computeSelectedData(array_data)
        })
    }, 500, { trailing: true })

    //eslint-disable-next-line
    const debounceFetchData = useCallback(fetchDataHandler, []);

    useEffect(() => {
        let getDate = async () => {
            setActiveIndex(undefined)
            debounceFetchData(generalFilters, timeZone)
        }
        getDate()
        //eslint-disable-next-line
    }, [generalFilters, timeZone, getOrganizationSummaryData, setLegendData])

    const toggleVisible = (entry, isInner) => {
        const matchKey = entry.name
        let filteredData = data.map((cur) => {
            if (!isInner && cur.fullSpecies.endsWith(entry.genus + ' ' + entry.name)) {
                return {
                    ...cur, visible: !cur.visible
                }
            } else if (isInner && cur.fullSpecies.startsWith(matchKey)) {
                return {
                    ...cur, visible: !cur.visible
                }
            }
            return cur
        })
        computeSelectedData(filteredData)
    }

    const toggleVisibleAll = () => {
        computeSelectedData(data.map((cur) => {
            return {
                ...cur, visible: !cur.visible
            }
        }))
    }
    const clearVisible = () => {
        computeSelectedData(data.map((cur) => {
            return {
                ...cur, visible: true
            }
        }))
    }

    const computeSelectedData = (filteredData) => {
        let data1 = [], data2 = [], order = 0, array = []
        for (let i of filteredData) {
            data2.push({ ...i, "name": i.species, "value": i.count, "genus": _.capitalize(i.genus) })
            if (data1[i?.genus]) {
                data1[i.genus] = { ...i, ...data1[i.genus], value: data1[i.genus].value + i.count }
            } else {
                data1[i.genus] = { ...i, name: _.capitalize(i.genus), value: i.count, order: order }
                order += 1
            }
        }
        for (var i in data1) {
            array.push(data1[i]);
        }
        setData(filteredData)
        setDataOuter(data2.filter((cur) => cur.visible))
        setDataInner(array.filter((cur) => cur.visible).sort((a, b) => parseFloat(a.order) - parseFloat(b.order)))
        setLegendData({
            dataOuter: data2,
            dataInner: (array.sort((a, b) => parseFloat(a.order) - parseFloat(b.order)))
        })
    }

    return (<>
        {expand &&
            <>
                <Box
                    className={
                        "flex absolute m-auto w-full h-full transition-opacity duration-1000"
                    }
                    id={"tray-modal"}
                    style={{
                        backgroundColor: "rgb(0, 0, 0, 0.33)",
                        zIndex: 35
                    }}
                >
                </Box>
                <Box
                    className={"absolute inset-0 m-auto rounded-3xl"}
                    sx={{
                        height: '100%',
                        width: '100%',
                        backgroundColor: "rgb(0, 0, 0, 0.25)",
                        filter: "blur(50px)",
                        zIndex: 35
                    }}
                ></Box>
                <Box
                    className={
                        "absolute m-auto filter blur-2xl inset-0"
                    }
                    sx={{
                        height: '95%',
                        width: '95%',
                        zIndex: 40
                    }}
                >
                    <DataSummaryGraph
                        generalFilters={generalFilters}
                        timeZone={timeZone}
                        setLegendData={setLegendData}
                        pointerEvent={graphPointerEvent}
                        dataInner={dataInner}
                        dataOuter={dataOuter}
                        noMatchData={noMatchData}
                        activeIndex={activeIndex}
                        setActiveIndex={setActiveIndex}
                    />
                </Box>
            </>
        }
        {(expand && legendData?.dataInner) && <DataSummaryGraphTableLegend
            dataInner={legendData.dataInner}
            dataOuter={legendData.dataOuter}
            setPointerEvent={setGraphPointerEvent}
            toggleVisible={toggleVisible}
            toggleVisibleAll={toggleVisibleAll}
            clearVisible={clearVisible}
        />}
        <Paper
            sx={{
                height: '15%',
                width: ["3rem", "3rem", "3rem", "4rem", "4rem", "4rem"],

                position: 'absolute',
                left: 0,
                "z-index": expand ? 70 : 35,
                top: '50%',
                transform: 'translateY(-50%)',
                alignItems: 'center',
                display: ['none', 'flex'],
                ":hover": {
                    width: ["130px", "130px", "130px", "180px", "180px", "180px"],
                    ".MuiMenuItem-root": {
                        maxWidth: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        ".MuiTypography-root": {
                            display: 'block'
                        }
                    }
                },
                ".MuiList-root": {
                    width: "100%"
                },
                ".MuiMenuItem-root": {
                    display: 'flex',
                    justifyContent: 'center',
                    ".MuiTypography-root": {
                        display: 'none'
                    }
                }
            }}
        >
            <MenuList>
                <MenuItem
                    onClick={() => setExpand(true)}
                >
                    <AutoGraphIcon />
                    <Typography>
                        Summary
                    </Typography>
                </MenuItem>
                <MenuItem
                    onClick={() => setExpand(false)}
                >
                    <CloseIcon />
                    <Typography>
                        Close
                    </Typography>
                </MenuItem>
            </MenuList>
        </Paper>
    </>)
};

export const mapStateToProps = (state) => {
    const { generalFilters } = state.data
    const { timeZone } = state.user
    const { algConfidence = 50 } = state.user
    return {
        generalFilters: { ...generalFilters, algConfidence },
        timeZone
    };
};

export default connect(mapStateToProps, {})(Index);
