import React from "react";
import SideBar from "./SideBar";
import Map from "./TrapsMap";
import ModalRouter from "./ModalRouter";
import DeviceSelector from "./DeviceSelector";
import TrapSiteButton from "./TrapSiteButton";
import UserMenu from "./UserMenu";
import CircularProgress from "@mui/material/CircularProgress";
import NotificationSnackbar from "../NotificationSnackbar";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/material";
import OrganizationSelector from "./OrganizationSelector";

const Home = ({ reconnectionMessage }) => {
  return (
    <div>
      {reconnectionMessage && (
        <div
          className={
            "h-screen w-screen absolute right-0 top-0 p-0 bg-black bg-opacity-50 flex flex-col flex-wrap content-center justify-center"
          }
          style={{ zIndex: 5000 }}
        >
          <CircularProgress
            thickness={4}
            size={"20rem"}
            className={"filter drop-shadow-lg"}
          />
          <h1
            className={"text-white filter drop-shadow-lg text-center text-4xl"}
          >
            {reconnectionMessage}
          </h1>
        </div>
      )}

      <Box
        className={"flex h-screen w-screen relatives"}
      >
        <Box
          className="flex"
          sx={{
            width: ["100vw", "350px", "450px", "450px", "33%", "33%"],
            maxWidth: ["100vw", "350px", "450px", "450px", "600px", "600px"],
            height: ["30%", "100%", "100%", "100%", "100%", "100%"],
            maxHeight: ["30%", "100%", "100%", "100%", "100%", "100%"],
          }}
        >
          <SideBar />
        </Box>
        <Grid
          // className={"flex flex-1 relative"}
          sx={{
            display: ['flex', 'flex', 'flex', 'flex', 'flex', 'flex'],
            position: ['none', 'relative', 'relative', 'relative', 'relative', 'relative'],
            flex: ['none', '1 1 auto', '1 1 auto', '1 1 auto', '1 1 auto', '1 1 auto'],
          }}
        >
          <Map />
          <ModalRouter />
          <DeviceSelector className={"fixed"} />
          <TrapSiteButton
            id={"trap-sites"}
            className={"fixed z-50 right-0 top-0"}
          />
          <UserMenu
            id={"user-dropdown-menu"}
            className={"fixed z-50 right-0 bottom-0"}
          />
          <OrganizationSelector />
          <NotificationSnackbar id={"user-notification-snackbar"} />

        </Grid>
      </Box>
    </div>
  );
};

export default Home;
