import React from "react";
import MenuItem from "@mui/material/MenuItem";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

const SettingsButton = (props) => {
  const { onClick } = props;
  return (
    <MenuItem color="primary" onClick={onClick}>
      <span>
        <AdminPanelSettingsIcon /> SuperUser Panel{" "}
      </span>
    </MenuItem>
  );
};

export default SettingsButton;
