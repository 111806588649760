import React from "react";
import MenuIcon from "@mui/icons-material/Menu";
import MenuList from "@mui/material/MenuList";
import Popper from "@mui/material/Popper";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Fab from "@mui/material/Fab";
import { Tooltip } from "@mui/material";
import LogoutButton from "./LogoutButton";
import SetupWifiButton from "./SetupWifiButton";
import SettingsButton from "./SettingsButton";
import RebootButton from "./RebootButton";
// import DeviceDetailsButton from "./DeviceDetailsButton";
import SuperUserPanelButton from "./SuperUserPanelButton";

const UserMenu = (props) => {
  const {
    otherProps,
    superUser,
    handleClick,
    handleClose,
    handleListKeyDown,
    anchorEl,
  } = props;
  const { ...other } = otherProps;
  return (
    <div {...other}>
      <Tooltip
        title={"User Menu"}
        sx={{ margin: ["1rem", "1rem", "1rem", "1.5rem", "1.5rem"] }}
      >
        <Fab
          aria-controls="user-menu"
          aria-haspopup="true"
          color="primary"
          onClick={handleClick}
        >
          <MenuIcon />
        </Fab>
      </Tooltip>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        // transition

        placement="left-end"
        style={{ zIndex: 100 }}
      >
        {({ TransitionProps, placement }) => (
          <Paper style={{ marginRight: "2rem", marginBottom: "2rem" }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                autoFocusItem={Boolean(anchorEl)}
                id="user-menu"
                onKeyDown={handleListKeyDown}
              >
                <RebootButton handleClose={handleClose} />
                <SetupWifiButton handleClose={handleClose} />
                <SettingsButton handleClose={handleClose} />
                {/* <DeviceDetailsButton handleClose={handleClose} /> */}
                {superUser ? (
                  <SuperUserPanelButton handleClose={handleClose} />
                ) : null}
                <LogoutButton handleClose={handleClose} />
              </MenuList>
            </ClickAwayListener>
          </Paper>
        )}
      </Popper>
    </div>
  );
};

export default UserMenu;
