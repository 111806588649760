import React from "react";
import TrapSiteButton from "./TrapSiteButton";
import { useHistory } from "react-router-dom";
import ROUTES from "../../AppRouter/routes";

const Index = (props) => {
  const { ...other } = props;
  let history = useHistory();

  const onClick = () => {
    history.push(ROUTES.trapSite);
  };

  return (
    <div {...other}>
      <TrapSiteButton onClick={onClick} />
    </div>
  );
};

export default Index;
