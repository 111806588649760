import React from "react";
import { Box, Button, SvgIcon } from "@mui/material";


const svgIconSx = {
  width: ["25px", "25px", "25px", "25px", "49px", "49px"],
  height: ["140px", "140px", "140px", "140px", "166px", "166px"]
}

const PreviousNextContainer = ({
  handlePreviousTray,
  handleNextTray,
  shouldDisablePreviousTray,
  shouldDisableNextTray,
  children,
}) => {
  return (
    <div className={"flex flex-row justify-between w-full"}>
      <div className={"flex justify-center"}>
        <Button
          onClick={handlePreviousTray}
          disabled={shouldDisablePreviousTray}
          aria-label="previous tray"
          sx={{
            mr: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", ".25rem", "0.25rem"],
            minWidth: ['2rem', '2rem', '3rem', '3rem', '4rem', '4rem']
          }}
        >
          <Box
            className={` ${shouldDisablePreviousTray ? "opacity-50" : ""
              }`}
            sx={{
              width: ["1rem", "1rem", "1rem", "2rem", "3rem", "3rem"],
              mr: ["0.5rem", "0.5rem", "0.5rem", "0.5rem", "1.5rem", "1.5rem"],
            }}
          >
            <SvgIcon
              sx={svgIconSx}
              viewBox="0 0 49 166"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M48.625 15.25L41.25 0.5L0 83L41.25 165.5L48.625 150.75L14.75 83L48.625 15.25Z"
                fill={shouldDisablePreviousTray ? "#EEEEEE" : "#DAF4FE"}
              />
            </SvgIcon>
          </Box>
        </Button>
      </div>
      <Box
      >{children}</Box>
      <div className={"flex justify-center"}>
        <Button
          onClick={handleNextTray}
          disabled={shouldDisableNextTray}
          aria-label="next tray"
        >
          <Box
            className={` ${shouldDisableNextTray ? "opacity-50" : ""}`}
            sx={{
              width: ["2rem", "2rem", "2rem", "2rem", "3rem", "3rem"],
              ml: ["0.5rem", "0.5rem", "0.5rem", "0.5rem", "1.5rem", "1.5rem"]
            }}
          >
            <SvgIcon
              sx={svgIconSx}
              viewBox="0 0 51 168"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.499512 18.3327L33.3328 83.9994L0.499512 149.666L9.33284 167.333L50.9995 83.9994L9.33284 0.666016L0.499512 18.3327Z"
                fill={shouldDisableNextTray ? "#EEEEEE" : "#DAF4FE"}
              />
            </SvgIcon>
          </Box>
        </Button>
      </div>
    </div >
  );
};

export default PreviousNextContainer;
