import React, { useEffect, useState } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import Data from "./Data";
import { fetchOrganizationFilters, updateGeneralFilters, clearGeneralFilters } from "./../../../../actions";
import { useWebServer } from "../../../../providers";

const fileDownload = require("js-file-download");

const Index = ({
  fetchOrganizationFilters,
  timeZone,
  generalFilters,
  updateGeneralFilters,
  clearGeneralFilters,
  options,
}) => {
  const [loading, setLoading] = useState(false);
  const { downloadOrganizationData, sendRequest, downloadOrganizationDataWithAbundance } = useWebServer();
  const [allDownloadConfirm, setAllDownloadConfirm] = useState(false);

  useEffect(() => {
    fetchOrganizationFilters(sendRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDownloadData = async ({ filters }, dataType = "data") => {
    setLoading(true);
    const response =
      (dataType === "data")
        ? await downloadOrganizationData({ filters })
        : await downloadOrganizationDataWithAbundance({ filters });
    const data = new Blob([response.data], {
      type: response.headers["content-type"],
    });
    fileDownload(data, `data_${new Date().toISOString()}.xlsx`);
    setLoading(false);
  };

  return (
    <Data
      handleDownloadData={onDownloadData}
      showLoading={loading}
      disableDownloadButton={loading}
      allDownloadConfirm={allDownloadConfirm}
      setAllDownloadConfirm={setAllDownloadConfirm}
      options={options}
      timeZone={timeZone}
      generalFilters={generalFilters}
      updateGeneralFilters={updateGeneralFilters}
      clearGeneralFilters={clearGeneralFilters}
    />
  );
};

export const mapStateToProps = (state) => {
  const { selectedDevice, ...devices } = state.devices;
  const { count, ...trapSites } = state.trapSites;
  const {
    contributedGenusSpecies = [],
    plateWells = {},
    trapType: trapTypes = [],
    algorithmGenusSpecies = [],
    users = [],
    tags: tagsOptions = []
  } = state.organization;
  const contributedPlates = Object.keys(
    _.pickBy(plateWells, (plate, wells) => wells.length > 0)
  );
  const { timeZone } = state.user
  const { generalFilters } = state.data
  const curDevices = Object.values(
    _.pickBy(
      devices,
      (value, _) => value?.env === process.env.REACT_APP_ENV.toUpperCase()
    )
  )
  const options = {
    deviceOptions: curDevices,
    trapSiteOptions: Object.values(trapSites),
    contributedGenusSpeciesOptions: contributedGenusSpecies,
    contributedPlatesOptions: contributedPlates,
    trapTypesOptions: trapTypes,
    algorithmGenusSpeciesOptions: algorithmGenusSpecies,
    usersOptions: users,
    tagsOptions
  }

  const { algConfidence = 50 } = state.user

  return {
    devices: curDevices,
    options,
    selectedDevice,
    timeZone,
    generalFilters: { ...generalFilters, algConfidence },
  };
};

export default connect(mapStateToProps, { fetchOrganizationFilters, updateGeneralFilters, clearGeneralFilters })(Index);
